import { createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center my-6 ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.hasSearch)
      ? (_openBlock(), _createBlock($setup["AtomInput"], {
          key: 0,
          prependIcon: "",
          noBgPrependIcon: "",
          noBorderPrependIcon: "",
          placeholder: "Pesquisar",
          onKeyup: _withKeys($setup.handleBlur, ["enter"]),
          modelValue: $setup.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
          width: "620px",
          classes: "check-class"
        }, {
          prependIcon: _withCtx(() => [
            _createVNode($setup["SearchIcon"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true),
    (!$props.hasSearch)
      ? (_openBlock(), _createBlock($setup["AtomButton"], {
          key: 1,
          prependIcon: "",
          appendIcon: "",
          background: "grey-lighten-4",
          outlined: "",
          text: "Limpar",
          style: {"margin-left":"10px"},
          onClick: $setup.clear
        }, {
          prependIcon: _withCtx(() => [
            _createVNode($setup["DeleteClear"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <AtomButton id=\"menu-activator\" prependIcon appendIcon background=\"white\" outlined text=\"Opções\"\n      style=\"margin-left: 10px\" v-if=\"hasOptions\">\n      <template #prependIcon>\n        <SettingIcon />\n      </template>\n      <template #appendIcon>\n        <ArrowDown3Icon />\n      </template>\n    </AtomButton> "),
    _createCommentVNode(" <v-menu activator=\"#menu-activator\">\n      <v-list>\n        <v-list-item value=\"download\">\n          <AtomButton @click=\"handleCopy\" prependIcon appendIcon background=\"white\" text=\"Copiar\">\n            <template #prependIcon>\n              <DownloadIcon />\n            </template>\n          </AtomButton>\n        </v-list-item>\n        <v-list-item value=\"downloadExcel\">\n          <AtomButton @click=\"handleDownloadExcel\" prependIcon appendIcon background=\"white\" text=\"Download Excel\">\n            <template #prependIcon>\n              <DownloadIcon />\n            </template>\n          </AtomButton>\n        </v-list-item>\n        <v-list-item value=\"downloadPDF\">\n          <AtomButton @click=\"handleDownloadPdf\" prependIcon appendIcon background=\"white\" text=\"Download PDF\">\n            <template #prependIcon>\n              <DownloadIcon />\n            </template>\n          </AtomButton>\n        </v-list-item>\n        <v-list-item value=\"printer\">\n          <AtomButton @click=\"handlePrinter\" prependIcon appendIcon background=\"white\" text=\"Imprimir tabela\">\n            <template #prependIcon>\n              <DownloadIcon />\n            </template>\n          </AtomButton>\n        </v-list-item>\n      </v-list>\n    </v-menu> "),
    _renderSlot(_ctx.$slots, "areaFilter")
  ]))
}