<template>
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31Z"
      fill="#FFEFE6" />
    <path opacity="0.6"
      d="M30.915 17.1308L27.5009 25.0783H24.0867C23.52 25.0783 22.9675 25.1208 22.4292 25.2341L23.8459 21.8341L23.9025 21.7066L23.9875 21.4799C24.03 21.3808 24.0584 21.2958 24.1009 21.2249C25.7442 17.4141 27.5859 16.2241 30.915 17.1308Z"
      fill="#FF5C00" />
    <path opacity="0.6"
      d="M40.5341 25.4609L40.5058 25.4467C39.6558 25.2059 38.7916 25.0784 37.9133 25.0784H29.0449L32.2324 17.6692L32.2749 17.5701C32.4733 17.6409 32.6858 17.7401 32.8983 17.8109L36.0291 19.1284C37.7716 19.8509 38.9899 20.6017 39.7408 21.5084C39.8683 21.6784 39.9816 21.8342 40.0949 22.0184C40.2224 22.2167 40.3216 22.4151 40.3783 22.6276C40.4349 22.7551 40.4774 22.8684 40.5058 22.9959C40.7183 23.7184 40.7324 24.5401 40.5341 25.4609Z"
      fill="#FF5C00" />
    <path
      d="M31.7366 39.0184H32.0907C32.5157 39.0184 32.8699 38.6359 32.8699 38.1684C32.8699 37.5734 32.6999 37.4884 32.3316 37.3468L31.7366 37.1343V39.0184Z"
      fill="#FF5C00" />
    <path
      d="M39.9108 27.4867C39.2733 27.3025 38.6075 27.2034 37.9133 27.2034H24.0867C23.1233 27.2034 22.2167 27.3875 21.3667 27.7559C18.9017 28.8184 17.1733 31.2692 17.1733 34.1167V36.8792C17.1733 37.2192 17.2017 37.545 17.2442 37.885C17.5558 42.39 19.9642 44.7984 24.4692 45.0959C24.795 45.1384 25.1208 45.1667 25.475 45.1667H36.525C41.7667 45.1667 44.5292 42.6734 44.7983 37.715C44.8125 37.4459 44.8267 37.1625 44.8267 36.8792V34.1167C44.8267 30.9859 42.7442 28.3509 39.9108 27.4867ZM32.8133 35.9584C33.465 36.185 34.3433 36.6667 34.3433 38.1684C34.3433 39.4575 33.3375 40.4917 32.0908 40.4917H31.7367V40.8034C31.7367 41.2142 31.4108 41.54 31 41.54C30.5892 41.54 30.2633 41.2142 30.2633 40.8034V40.4917H30.1358C28.7758 40.4917 27.6567 39.3442 27.6567 37.9275C27.6567 37.5167 27.9825 37.1909 28.3933 37.1909C28.8042 37.1909 29.13 37.5167 29.13 37.9275C29.13 38.5225 29.5833 39.0184 30.1358 39.0184H30.2633V36.6242L29.1867 36.2417C28.535 36.015 27.6567 35.5334 27.6567 34.0317C27.6567 32.7425 28.6625 31.7084 29.9092 31.7084H30.2633V31.3967C30.2633 30.9859 30.5892 30.66 31 30.66C31.4108 30.66 31.7367 30.9859 31.7367 31.3967V31.7084H31.8642C33.2242 31.7084 34.3433 32.8559 34.3433 34.2725C34.3433 34.6834 34.0175 35.0092 33.6067 35.0092C33.1958 35.0092 32.87 34.6834 32.87 34.2725C32.87 33.6775 32.4167 33.1817 31.8642 33.1817H31.7367V35.5759L32.8133 35.9584Z"
      fill="#FF5C00" />
    <path
      d="M29.1299 34.0316C29.1299 34.6266 29.2999 34.7116 29.6682 34.8533L30.2632 35.0658V33.1816H29.909C29.4699 33.1816 29.1299 33.5641 29.1299 34.0316Z"
      fill="#FF5C00" />
  </svg>
</template>
<script lang="ts" setup>

</script>
