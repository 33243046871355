import { ITab } from "@/types";
import { reactive, computed } from "vue";

const state = reactive<{ loading: boolean; drawer: boolean; isMobile: boolean; routesSettings: ITab[], routesHome: ITab[], routesAnalitics: ITab[] }>({
  loading: false,
  drawer: true,
  isMobile: false,
  routesSettings: [
    {
      label: 'Grupos',
      value: 'groups',
      nameRoute: 'Grupos',
    },
    {
      label: 'Empresas',
      value: 'companys',
      nameRoute: 'Empresas'
    },
    {
      label: 'Unidades',
      value: 'units',
      nameRoute: 'Unidades'
    },
    {
      label: 'Tipo de equipamentos',
      value: 'typeEquipaments',
      nameRoute: 'TipoEquipamento'
    },
    {
      label: 'Equipamentos',
      value: 'equipaments',
      nameRoute: 'Equipamentos'
    },
    {
      label: 'Provedor',
      value: 'provedor',
      nameRoute: 'Proverdor'
    },
    {
      label: 'Link',
      value: 'internetLink',
      nameRoute: 'LinkInternet'
    },
    {
      label: 'Serviços',
      value: 'services',
      nameRoute: 'Servicos'
    },
    {
      label: 'Coletores',
      value: 'collectors',
      nameRoute: 'Coletores'
    },
    {
      label: 'Versão dos Coletores',
      value: 'collectorVersion',
      nameRoute: 'collectorVersion'
    },
    {
      label: 'Gatilhos',
      value: 'triggers',
      nameRoute: 'triggers'
    },
  ],
  routesHome: [
    {
      label: 'Observabilidade',
      value: 'overview',
      nameRoute: 'Overview',
    },
    {
      label: 'Informações financeiras',
      value: 'financeInfo',
      nameRoute: 'financeInfo'
    },
    // {
    //   label: 'Monitor tempo Real',
    //   value: 'monitorTempoReal',
    //   nameRoute: 'monitorTempoReal'
    // },
  ],
  routesAnalitics: [
    {
      label: 'Análise Coletor Saltos',
      value: 'jump_collector',
      nameRoute: 'ColetorSalto',
    },
    {
      label: 'Quadrante',
      value: 'quadrant',
      nameRoute: 'Quadrante',
    },
    {
      label: 'Coletores em Execução',
      value: 'collectorInstalled',
      nameRoute: 'ColetorExecucao',
    },
  ],
});

const actions = {
  ActionSetPreloader(payload: boolean) {
    state.loading = payload;
  },

  handleToggleMenu() {
    state.drawer = !state.drawer
  },

  handleIsMobile() {
    if (window.innerWidth < 900) {
      state.isMobile = true
    }
    else {
      state.isMobile = false
    }
  }

};
const getters = {
  isLoading: computed(() => state.loading),
  drawer: computed(() => state.drawer),
  time: computed(() => {
    const dataAtual = new Date()
    const dataString = dataAtual.toLocaleDateString()
    const horas = String(dataAtual.getHours()).padStart(2, '0')
    const minutos = String(dataAtual.getMinutes()).padStart(2, '0')
    const segundos = String(dataAtual.getSeconds()).padStart(2, '0')
    const horaAtual = `${horas}:${minutos}:${segundos}`

    return `${dataString} - ${horaAtual}`
  }


  ),
};
export { getters, state, actions };
