<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.3" y="0.3" width="23.4" height="23.4" rx="2.1" fill="white" stroke="#D6DBE1" stroke-width="0.6" />
    <path
      d="M14.3271 12.0001C14.3271 13.2871 13.2871 14.3271 12.0001 14.3271C10.7131 14.3271 9.6731 13.2871 9.6731 12.0001C9.6731 10.7131 10.7131 9.6731 12.0001 9.6731C13.2871 9.6731 14.3271 10.7131 14.3271 12.0001Z"
      stroke="#292E36" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M11.9999 17.3754C14.2944 17.3754 16.4329 16.0234 17.9214 13.6834C18.5064 12.7669 18.5064 11.2264 17.9214 10.3099C16.4329 7.96992 14.2944 6.61792 11.9999 6.61792C9.7054 6.61792 7.5669 7.96992 6.0784 10.3099C5.4934 11.2264 5.4934 12.7669 6.0784 13.6834C7.5669 16.0234 9.7054 17.3754 11.9999 17.3754Z"
      stroke="#292E36" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script lang="ts" setup>

</script>
