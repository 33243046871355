<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M14.897 2.8421C15.311 2.8421 15.647 3.1781 15.647 3.5921C15.647 4.0061 15.311 4.3421 14.897 4.3421H7.629C5.121 4.3421 3.5 6.0661 3.5 8.7361V16.8181C3.5 19.5231 5.082 21.2031 7.629 21.2031H16.233C18.741 21.2031 20.362 19.4821 20.362 16.8181V9.7791C20.362 9.3651 20.698 9.0291 21.112 9.0291C21.526 9.0291 21.862 9.3651 21.862 9.7791V16.8181C21.862 20.3381 19.6 22.7031 16.233 22.7031H7.629C4.262 22.7031 2 20.3381 2 16.8181V8.7361C2 5.2111 4.262 2.8421 7.629 2.8421H14.897ZM17.0124 9.6719C17.3404 9.9259 17.4004 10.3969 17.1464 10.7239L14.2164 14.5039C14.0944 14.6619 13.9144 14.7649 13.7164 14.7889C13.5164 14.8159 13.3184 14.7579 13.1604 14.6349L10.3424 12.4209L7.8114 15.7099C7.6634 15.9019 7.4414 16.0029 7.2164 16.0029C7.0564 16.0029 6.8954 15.9519 6.7594 15.8479C6.4314 15.5949 6.3694 15.1239 6.6224 14.7959L9.6154 10.9059C9.7374 10.7469 9.9184 10.6439 10.1164 10.6189C10.3184 10.5929 10.5164 10.6489 10.6734 10.7739L13.4934 12.9889L15.9604 9.8059C16.2144 9.4769 16.6844 9.4159 17.0124 9.6719ZM19.9674 2C21.4414 2 22.6394 3.198 22.6394 4.672C22.6394 6.146 21.4414 7.345 19.9674 7.345C18.4944 7.345 17.2954 6.146 17.2954 4.672C17.2954 3.198 18.4944 2 19.9674 2ZM19.9674 3.5C19.3214 3.5 18.7954 4.025 18.7954 4.672C18.7954 5.318 19.3214 5.845 19.9674 5.845C20.6134 5.845 21.1394 5.318 21.1394 4.672C21.1394 4.025 20.6134 3.5 19.9674 3.5Z"
      :fill="color || '#212529'" />
  </svg>
</template>
<script lang="ts" setup>

</script>
