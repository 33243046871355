import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { Companhia } from '../models'

const controller = 'companhias'

export async function getCompanhiaAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {

    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<Companhia[], AxiosResponse>(`${controller}?page=${page}&archived=0&size=${size}${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getCompanhia(id: string): Promise<Companhia> {

    const { data } = await httpAnalitcs.get<Companhia, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postCompanhia(payload: Companhia): Promise<Companhia> {

    const { data } = await httpAnalitcs.post<Companhia, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putCompanhia(payload: Companhia): Promise<Companhia> {

    const { data } = await httpAnalitcs.put<Companhia, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteCompany(CompanhiaId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${CompanhiaId}`);

    return status === 204;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }