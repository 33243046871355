import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { InterfaceDispositivos } from '../models'

const controller = 'InterfaceDispositivos'

export async function getInterfaceDispositivosAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<InterfaceDispositivos[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getGrupo(id: string): Promise<InterfaceDispositivos> {

    const { data } = await httpAnalitcs.get<InterfaceDispositivos, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postGrupo(payload: InterfaceDispositivos): Promise<InterfaceDispositivos> {

    const { data } = await httpAnalitcs.post<InterfaceDispositivos, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putGrupo(payload: InterfaceDispositivos): Promise<InterfaceDispositivos> {

    const { data } = await httpAnalitcs.put<InterfaceDispositivos, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteGrupo(grupoId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${grupoId}`);

    return status === 204;
}

// export async function patchGrupo(payload: InterfaceDispositivos): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }