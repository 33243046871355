<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M22.25 10.76C22.25 10.29 21.87 9.90002 21.39 9.90002H3.11C2.63 9.90002 2.25 10.28 2.25 10.76C2.25 16.65 6.36 20.76 12.25 20.76C18.14 20.76 22.25 16.64 22.25 10.76Z"
      fill="#45505F"
    />
    <path
      d="M12.78 3.45998L15.63 6.29998C15.92 6.58998 15.92 7.06998 15.63 7.35998C15.34 7.64998 14.86 7.64998 14.57 7.35998L13 5.79998V15.37C13 15.78 12.66 16.12 12.25 16.12C11.84 16.12 11.5 15.78 11.5 15.37V5.79998L9.94002 7.36998C9.65002 7.65998 9.17002 7.65998 8.88002 7.36998C8.73002 7.21998 8.65002 7.02998 8.65002 6.83998C8.65002 6.64998 8.72002 6.45998 8.87002 6.30998L11.72 3.46998C12.01 3.16998 12.49 3.16998 12.78 3.45998Z"
      fill="#45505F"
    />
  </svg>
</template>
<script lang="ts" setup></script>
