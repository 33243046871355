import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { GatilhoNotificacao } from '@/core/models';

const controller = 'gatilhos-notificacao'

export async function getTriggersAll(page: number, size: number, sort: any = { archived: "asc", id: "desc" }): Promise<any> {

    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')


    const { data } = await httpAnalitcs.get<GatilhoNotificacao[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(sort ? `&sort=${stringSort}` : "")}`);
    return data;

}
export async function listaGatilhos() {
    const { data } = await httpAnalitcs.get<GatilhoNotificacao[], AxiosResponse>(`lista-dinamica/CondicaoGatilhoEnum`);
    return data;
}

export async function listaIntervaoNotificacao() {
    const { data } = await httpAnalitcs.get<GatilhoNotificacao[], AxiosResponse>(`lista-dinamica/IntervaloTempoNotificaEnum`);
    return data;
}

export async function listaOrdem() {
    const { data } = await httpAnalitcs.get<GatilhoNotificacao[], AxiosResponse>(`lista-dinamica/OrdemNotificacaoEnum`);
    return data;
}

export async function getTriggers(id: string): Promise<GatilhoNotificacao> {

    const { data } = await httpAnalitcs.get<GatilhoNotificacao, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postTriggers(payload: GatilhoNotificacao): Promise<GatilhoNotificacao> {
    const { data } = await httpAnalitcs.post<GatilhoNotificacao, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putTriggers(payload: GatilhoNotificacao): Promise<GatilhoNotificacao> {

    const { data } = await httpAnalitcs.put<GatilhoNotificacao, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteTriggers(GatilhoNotificacaoId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${GatilhoNotificacaoId}`);

    return status === 204;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }






