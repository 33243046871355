<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.4672 0C14.1784 0 16 1.9024 16 4.7328V11.2672C16 14.0976 14.1784 16 11.4656 16H4.5312C1.8208 16 0 14.0976 0 11.2672V4.7328C0 1.9024 1.8208 0 4.5312 0H11.4672ZM11.4672 1.2H4.5312C2.508 1.2 1.2 2.5864 1.2 4.7328V11.2672C1.2 13.4136 2.508 14.8 4.5312 14.8H11.4656C13.4912 14.8 14.8 13.4136 14.8 11.2672V4.7328C14.8 2.5864 13.4912 1.2 11.4672 1.2ZM7.9952 7.4C8.3264 7.4 8.5952 7.6688 8.5952 8V11.2C8.5952 11.5312 8.3264 11.8 7.9952 11.8C7.664 11.8 7.3952 11.5312 7.3952 11.2V8C7.3952 7.6688 7.664 7.4 7.9952 7.4ZM7.99912 4.16328C8.44152 4.16328 8.79912 4.52088 8.79912 4.96328C8.79912 5.40568 8.44152 5.76328 7.99912 5.76328C7.55672 5.76328 7.19512 5.40568 7.19512 4.96328C7.19512 4.52088 7.54952 4.16328 7.99112 4.16328H7.99912Z"
    fill="#000000"
    stroke="#000000"
    stroke-width="0.4"
  />
</svg>


</template>
<script lang="ts" setup>

</script>
