import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Agente\" /> "),
    (!$setup.state.isMobile)
      ? (_openBlock(), _createBlock($setup["MoleculeTabs"], {
          key: 0,
          modelValue: $setup.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.tab) = $event)),
          tabs: $setup.tabs
        }, null, 8 /* PROPS */, ["modelValue", "tabs"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeTabWindow"], {
      modelValue: $setup.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.tab) = $event)),
      class: _normalizeClass({ border: !$setup.state.isMobile })
    }, {
      default: _withCtx(() => [
        _createCommentVNode(" <MoleculeTabItem value=\"agent\">\n      <AgentPage :activeTab=\"tab\" />\n    </MoleculeTabItem> "),
        _createVNode($setup["MoleculeTabItem"], { value: "jump_collector" }, {
          default: _withCtx(() => [
            _createVNode($setup["JumpCollectorAnaliysisPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "quadrant" }, {
          default: _withCtx(() => [
            _createVNode($setup["QuadrantPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "collectorInstalled" }, {
          default: _withCtx(() => [
            _createVNode($setup["CollectorInstalledPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"])
  ], 64 /* STABLE_FRAGMENT */))
}