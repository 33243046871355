import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e229c5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "area" }
const _hoisted_2 = { class: "step1" }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: String(step.label),
        class: "d-flex flex-column align-center relative"
      }, [
        _createElementVNode("p", {
          style: _normalizeStyle({ color: $props.currentStep === step.label ? '#212529' : '' }),
          class: "title-step"
        }, _toDisplayString(step.label), 5 /* TEXT, STYLE */),
        _createElementVNode("div", _hoisted_2, [
          (step.completed)
            ? (_openBlock(), _createBlock($setup["CompletedStepIcon"], { key: 0 }))
            : ($props.currentStep === step.label)
              ? (_openBlock(), _createBlock($setup["StepActiveIcon"], { key: 1 }))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(index + 1), 1 /* TEXT */))
        ]),
        _createElementVNode("div", {
          style: _normalizeStyle({ background: $props.currentStep === step.label || step.completed ? '#FFDECC' : '' }),
          class: "line-step-l"
        }, null, 4 /* STYLE */),
        _createElementVNode("div", {
          style: _normalizeStyle({ background: step.completed ? '#FFDECC' : '' }),
          class: "line-step-r"
        }, null, 4 /* STYLE */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}