import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b7f4d71"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["value", "label", "disabled", "checked"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["value", "label", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createElementVNode("input", _mergeProps({
            type: "checkbox",
            value: $props.modelValue,
            class: $setup.props.classes ?? ''
          }, $setup.attributes, {
            label: $setup.props.label,
            disabled: $props.disabled,
            checked: $props.checked
          }, _toHandlers($setup.listeners, true)), null, 16 /* FULL_PROPS */, _hoisted_2),
          ($setup.props.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.props.label), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass($props.checked ? 'label-check-on  my-2': 'label-check  my-2')
        }, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "10",
                class: "mt-3 ps-5"
              }, {
                default: _withCtx(() => [
                  ($setup.props.label)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.props.label), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "2",
                class: "mt-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("input", _mergeProps({
                    type: "checkbox",
                    value: $props.modelValue,
                    class: 'input-mobile'
                  }, $setup.attributes, {
                    label: $setup.props.label,
                    disabled: $props.disabled,
                    checked: $props.checked
                  }, _toHandlers($setup.listeners, true)), null, 16 /* FULL_PROPS */, _hoisted_5)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <v-checkbox color=\"orange\" :value=\"modelValue\" v-bind=\"attributes\" :label=\"label\" :disabled=\"disabled\"\n    v-on=\"listeners\"></v-checkbox> ")
  ], 64 /* STABLE_FRAGMENT */))
}