<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.659 2C19.989 2 22.5 4.717 22.5 8.32V15.188C22.5 17.032 21.848 18.698 20.663 19.88C19.6 20.939 18.221 21.5 16.675 21.5H6.822C5.279 21.5 3.901 20.94 2.837 19.88C1.652 18.698 1 17.032 1 15.188V8.32C1 4.717 3.511 2 6.841 2H16.659ZM16.659 3.5H6.841C4.326 3.5 2.5 5.527 2.5 8.32V15.188C2.5 16.631 2.996 17.92 3.896 18.817C4.672 19.592 5.685 20 6.825 20H16.659C16.661 19.998 16.669 20 16.675 20C17.816 20 18.828 19.592 19.604 18.817C20.505 17.92 21 16.631 21 15.188V8.32C21 5.527 19.174 3.5 16.659 3.5ZM18.235 8.1288C18.496 8.4498 18.447 8.9218 18.126 9.1838L13.682 12.7958C13.12 13.2418 12.448 13.4648 11.777 13.4648C11.108 13.4648 10.441 13.2438 9.883 12.8018L5.398 9.1858C5.075 8.9258 5.025 8.4528 5.284 8.1308C5.545 7.8098 6.017 7.7588 6.339 8.0178L10.82 11.6298C11.383 12.0758 12.176 12.0758 12.743 11.6258L17.179 8.0198C17.501 7.7568 17.973 7.8068 18.235 8.1288Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
