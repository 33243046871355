import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'

export async function gerarDadosFinanceiros(mes: number, ano: number, units: number[] = []): Promise<any[]> {
    // https://goconnect-s.ceartico.com/gca/api/v1/gerarDadosFinanceiros/8/2023
    const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`gerarDadosFinanceiros/${mes}/${ano}${queryUnits}`);
    return data;
}

export async function listarDadosFinanceiros(mes: number, ano: number, units: number[] = []): Promise<any[]> {
    //https://goconnect-s.ceartico.com/gca/api/v1/listarDadosFinanceiros/8/2023
    const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`listarDadosFinanceiros/${mes}/${ano}${queryUnits}`)
    return data;
}


export async function uploadNF(id: number, file: File): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/gca/api/v1/uploadNotaFiscal
    const form = new FormData();
    form.append("id", String(id));
    form.append("notaFiscal", file)

    const { data } = await httpAnalitcs.post<any, AxiosResponse>(`uploadNotaFiscal`, form, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return data;
}

export async function downloadNF(fflinkId: number): Promise<any> {

    //https://goconnect-s.ceartico.com/gca/api/v1/gca/api/v1/downloadNotaFiscal/999

    const { data } = await httpAnalitcs.get(`downloadNotaFiscal/${fflinkId}`);
    return data;

}


export async function alterarDadosFinanceiros(payload: any): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/gca/api/v1/alterarDadosFinanceiros
    const { data } = await httpAnalitcs.put(`alterarDadosFinanceiros`, payload);
    return data;
}


export async function adicionarGastoExtra(payload: any): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/adicionarGastoExtra
    const { data } = await httpAnalitcs.post(`adicionarGastoExtra`, payload)

    return data;
}


export async function alterarGastoExtra(payload: any): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/alterarGastoExtra
    const { data } = await httpAnalitcs.put(`alterarGastoExtra`, payload)

    return data;
}



export async function listarGastoExtra(id: number): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/listarGastoExtra/2124
    const { data } = await httpAnalitcs.get(`listarGastoExtra/${id}`);

    return data;
}
export async function removerGastoExtra(id: number): Promise<any> {
    //https://goconnect-s.ceartico.com/gca/api/v1/removerGastoExtra/2124
    const { data } = await httpAnalitcs.delete(`removerGastoExtra/${id}`);
    return data;
}
