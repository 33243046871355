<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M29.3327 16C29.3327 23.3638 23.3631 29.3333 15.9993 29.3333C8.63555 29.3333 2.66602 23.3638 2.66602 16C2.66602 8.63616 8.63555 2.66663 15.9993 2.66663C23.3631 2.66663 29.3327 8.63616 29.3327 16Z" fill="#0095FF"/>
<path d="M21.7069 17.6799C21.7069 17.4266 21.6135 17.1732 21.4135 16.9732L16.7069 12.2666C16.3202 11.8799 15.6802 11.8799 15.2935 12.2666L10.5869 16.9732C10.2002 17.3599 10.2002 17.9999 10.5869 18.3866C10.9735 18.7732 11.6135 18.7732 12.0002 18.3866L16.0002 14.3866L20.0002 18.3866C20.3869 18.7732 21.0269 18.7732 21.4135 18.3866C21.6135 18.1999 21.7069 17.9466 21.7069 17.6799Z" fill="#0095FF"/>
</svg>

</template>
<script lang="ts" setup>

</script>
