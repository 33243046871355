import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "mx-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!

  return (_openBlock(), _createBlock(_component_v_tabs, {
    modelValue: $setup.tab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.tab) = $event)),
    color: "primary",
    class: "w-100"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.tabs, (itemTab) => {
        return (_openBlock(), _createBlock(_component_v_tab, {
          value: itemTab.value,
          key: itemTab.value,
          class: "text-muted text-md",
          color: $props.color
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(itemTab.label), 1 /* TEXT */),
            ($setup.props.textTooltip && itemTab.tooltips != '' )
              ? (_openBlock(), _createBlock($setup["AtomTooltip"], {
                  key: 0,
                  text: itemTab.tooltips
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AlertIcon"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "color"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}