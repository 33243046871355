<template>
<svg width="365" height="2" viewBox="0 0 365 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" d="M1 1L364 1" stroke="url(#paint0_linear_981_14179)" stroke-linecap="round"/>
<defs>
<linearGradient id="paint0_linear_981_14179" x1="1" y1="1.5" x2="364" y2="1.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFEFE6"/>
<stop offset="0.153613" stop-color="#FFBE99"/>
<stop offset="0.848007" stop-color="#FFBE99"/>
<stop offset="1" stop-color="#FFEFE6"/>
</linearGradient>
</defs>
</svg>

</template>
<script lang="ts" setup>

</script>
