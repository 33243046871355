import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MoleculeCard"], {
    textTooltip: $props.textTooltip,
    title: $setup.props.title,
    loading: $setup.props.loading,
    onOnChangeDropDownList: $setup.handleChangeDropdownList,
    "has-dropdown-list": $setup.props.hasDropdownList,
    "options-drop-down": $setup.props.optionsDropDown
  }, {
    default: _withCtx(() => [
      ($setup.props.hasToggle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["AtomToogleButton"], {
              width: "172px",
              buttons: $setup.props.buttons ?? [],
              onHandleToggle: $setup.handleToogleChange
            }, null, 8 /* PROPS */, ["buttons"])
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" <vue-highcharts :options=\"chartOptions\" :redrawOnUpdate=\"true\" :oneToOneUpdate=\"false\" :animateOnUpdate=\"true\" /> "),
      _createVNode($setup["PolarArea"], {
        data: $setup.data,
        options: $setup.options
      }, null, 8 /* PROPS */, ["data", "options"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["textTooltip", "title", "loading", "has-dropdown-list", "options-drop-down"]))
}