<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19.5" stroke="#B2BBC7"/>
<path opacity="0.4" d="M23.48 11H15.52C12.07 11 10 13.06 10 16.52V24.47C10 27.94 12.07 30 15.52 30H23.47C26.93 30 28.99 27.94 28.99 24.48V16.52C29 13.06 26.93 11 23.48 11Z" fill="#546375"/>
<path d="M29.0195 10.9803C27.2295 9.18028 25.4795 9.14028 23.6395 10.9803L22.5095 12.1003C22.4095 12.2003 22.3795 12.3403 22.4195 12.4703C23.1195 14.9203 25.0795 16.8803 27.5295 17.5803C27.5595 17.5903 27.6095 17.5903 27.6395 17.5903C27.7395 17.5903 27.8395 17.5503 27.9095 17.4803L29.0195 16.3603C29.9295 15.4503 30.3795 14.5803 30.3795 13.6903C30.3795 12.7903 29.9295 11.9003 29.0195 10.9803Z" fill="#546375"/>
<path d="M25.8611 18.4198C25.5911 18.2898 25.3311 18.1598 25.0911 18.0098C24.8911 17.8898 24.6911 17.7598 24.5011 17.6198C24.3411 17.5198 24.1611 17.3698 23.9811 17.2198C23.9611 17.2098 23.9011 17.1598 23.8211 17.0798C23.5111 16.8298 23.1811 16.4898 22.8711 16.1198C22.8511 16.0998 22.7911 16.0398 22.7411 15.9498C22.6411 15.8398 22.4911 15.6498 22.3611 15.4398C22.2511 15.2998 22.1211 15.0998 22.0011 14.8898C21.8511 14.6398 21.7211 14.3898 21.6011 14.1298C21.4711 13.8498 21.3711 13.5898 21.2811 13.3398L15.9011 18.7198C15.5511 19.0698 15.2111 19.7298 15.1411 20.2198L14.7111 23.1998C14.6211 23.8298 14.7911 24.4198 15.1811 24.8098C15.5111 25.1398 15.9611 25.3098 16.4611 25.3098C16.5711 25.3098 16.6811 25.2998 16.7911 25.2898L19.7611 24.8698C20.2511 24.7998 20.9111 24.4698 21.2611 24.1098L26.6411 18.7298C26.3911 18.6498 26.1411 18.5398 25.8611 18.4198Z" fill="#546375"/>
</svg>

</template>

