<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.877 14.456L9.99611 14.456L10.2601 14.4567C12.502 14.4687 17.754 14.6725 17.754 18.137C17.754 21.3491 13.4406 21.7783 10.0854 21.7945L9.49393 21.7943C7.25199 21.7823 2 21.5785 2 18.115C2 14.835 6.495 14.456 9.877 14.456ZM9.877 15.956C5.646 15.956 3.5 16.683 3.5 18.115C3.5 19.561 5.646 20.295 9.877 20.295C14.108 20.295 16.254 19.568 16.254 18.137C16.254 16.689 14.108 15.956 9.877 15.956ZM19.2041 7.9187C19.6181 7.9187 19.9541 8.2547 19.9541 8.6687L19.954 9.923L21.25 9.9238C21.664 9.9238 22 10.2598 22 10.6738C22 11.0878 21.664 11.4238 21.25 11.4238L19.954 11.423L19.9541 12.6797C19.9541 13.0937 19.6181 13.4297 19.2041 13.4297C18.7901 13.4297 18.4541 13.0937 18.4541 12.6797L18.454 11.423L17.16 11.4238C16.746 11.4238 16.41 11.0878 16.41 10.6738C16.41 10.2598 16.746 9.9238 17.16 9.9238L18.454 9.923L18.4541 8.6687C18.4541 8.2547 18.7901 7.9187 19.2041 7.9187ZM9.877 2C12.81 2 15.195 4.386 15.195 7.318C15.195 10.25 12.81 12.636 9.877 12.636H9.846C8.427 12.631 7.097 12.074 6.1 11.07C5.102 10.065 4.555 8.731 4.55997 7.315C4.55997 4.386 6.945 2 9.877 2ZM9.877 3.5C7.773 3.5 6.05997 5.213 6.05997 7.318C6.056 8.337 6.448 9.292 7.163 10.013C7.879 10.733 8.833 11.132 9.849 11.136L9.877 11.877V11.136C11.982 11.136 13.695 9.423 13.695 7.318C13.695 5.213 11.982 3.5 9.877 3.5Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
