<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.84 18.1932C19.84 21.4892 15.32 21.8702 11.921 21.8702L11.6778 21.8699C9.5122 21.8647 4 21.728 4 18.1732C4 14.9444 8.33835 14.5129 11.7115 14.4967L12.1642 14.4964C14.3296 14.5016 19.84 14.6383 19.84 18.1932ZM11.921 15.9962C7.66 15.9962 5.5 16.7282 5.5 18.1732C5.5 19.6312 7.66 20.3702 11.921 20.3702C16.181 20.3702 18.34 19.6382 18.34 18.1932C18.34 16.7352 16.181 15.9962 11.921 15.9962ZM11.921 1.99976C14.849 1.99976 17.23 4.38176 17.23 7.30976C17.23 10.2378 14.849 12.6188 11.921 12.6188H11.889C8.967 12.6098 6.6 10.2268 6.60997 7.30676C6.60997 4.38176 8.992 1.99976 11.921 1.99976ZM11.921 3.42776C9.78 3.42776 8.03798 5.16876 8.03798 7.30976C8.031 9.44376 9.76 11.1838 11.892 11.1918L11.921 11.9058V11.1918C14.061 11.1918 15.802 9.44976 15.802 7.30976C15.802 5.16876 14.061 3.42776 11.921 3.42776Z"
      :fill="color || '#ADB5BD'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
