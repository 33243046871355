import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d0cf1ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    density: "compact",
    class: _normalizeClass(`${$setup.state.isMobile ? 'w-100 rounded-pill area-btns-mobile' : 'area-btns '}`)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.buttons, (col, index) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        width: $setup.state.isMobile ? $setup.props.width : '',
        elevation: "0",
        onClick: ($event: any) => ($setup.toggleBtn = col.value),
        key: String(index),
        class: _normalizeClass(["btn-text", [{ active: $setup.toggleBtn === col.value }, `${$setup.state.isMobile ? 'radius-mobile ' : 'radius-desk'}`]]),
        style: _normalizeStyle({
        color: $setup.toggleBtn === col.value && col.textColor ? `${col.textColor}!important` : '',
        backgroundColor: $setup.toggleBtn === col.value && col.bgColor ? `${col.bgColor}!important` : '',
        opacity: $setup.toggleBtn === col.value ? 1 : 0.8,
      })
      }, {
        default: _withCtx(() => [
          (col.tooltip)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _createTextVNode(_toDisplayString(col.text) + " ", 1 /* TEXT */),
                _createVNode($setup["AtomTooltip"], {
                  text: col.tooltip
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AlertIcon"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(col.text), 1 /* TEXT */))
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["width", "onClick", "class", "style"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}