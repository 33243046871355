<template>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="18" fill="#F6F7F9"/>
<path d="M27.0697 11.23C25.4597 11.07 23.8497 10.95 22.2297 10.86V10.85L22.0097 9.55C21.8597 8.63 21.6397 7.25 19.2997 7.25H16.6797C14.3497 7.25 14.1297 8.57 13.9697 9.54L13.7597 10.82C12.8297 10.88 11.8997 10.94 10.9697 11.03L8.92967 11.23C8.50967 11.27 8.20967 11.64 8.24967 12.05C8.28967 12.46 8.64967 12.76 9.06967 12.72L11.1097 12.52C16.3497 12 21.6297 12.2 26.9297 12.73C26.9597 12.73 26.9797 12.73 27.0097 12.73C27.3897 12.73 27.7197 12.44 27.7597 12.05C27.7897 11.64 27.4897 11.27 27.0697 11.23Z" fill="#8897A8"/>
<path opacity="0.4" d="M25.2317 14.14C24.9917 13.89 24.6617 13.75 24.3217 13.75H11.6817C11.3417 13.75 11.0017 13.89 10.7717 14.14C10.5417 14.39 10.4117 14.73 10.4317 15.08L11.0517 25.34C11.1617 26.86 11.3017 28.76 14.7917 28.76H21.2117C24.7017 28.76 24.8417 26.87 24.9517 25.34L25.5717 15.09C25.5917 14.73 25.4617 14.39 25.2317 14.14Z" fill="#8897A8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.5781 23C15.5781 22.5858 15.9139 22.25 16.3281 22.25H19.6581C20.0723 22.25 20.4081 22.5858 20.4081 23C20.4081 23.4142 20.0723 23.75 19.6581 23.75H16.3281C15.9139 23.75 15.5781 23.4142 15.5781 23Z" fill="#8897A8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.75 19C14.75 18.5858 15.0858 18.25 15.5 18.25H20.5C20.9142 18.25 21.25 18.5858 21.25 19C21.25 19.4142 20.9142 19.75 20.5 19.75H15.5C15.0858 19.75 14.75 19.4142 14.75 19Z" fill="#8897A8"/>
</svg>

</template>

