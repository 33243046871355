<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E2E6E9" />
    <path
      d="M13.3132 21.0201C13.1357 20.8425 13.1195 20.5648 13.2648 20.369L13.3132 20.313L17.6261 15.9998L13.3132 11.6867C13.1357 11.5092 13.1195 11.2314 13.2648 11.0357L13.3132 10.9796C13.4907 10.8021 13.7685 10.786 13.9642 10.9312L14.0203 10.9796L18.687 15.6463C18.8645 15.8238 18.8806 16.1016 18.7354 16.2973L18.687 16.3534L14.0203 21.0201C13.825 21.2153 13.5085 21.2153 13.3132 21.0201Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
