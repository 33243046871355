import { reactive, computed } from "vue";
import { login } from "@/core/services/AuthService";
import * as storage from "./storage"
// import * as Types from "@/types";
import { Credential, LoginResponse } from "@/core/models";

let token = localStorage.getItem("token");

if (token) token = JSON.parse(String(localStorage.getItem(token)));

const state = reactive<LoginResponse>({
  id: 0,
  nome: '',
  email: '',
  fotoBase64: '',
  age: 0,
  token: '',
  papelLista: [],
  recursoLista: []
});

function ActionSetUser(payload: LoginResponse) {
  state.email = payload.email;
  state.fotoBase64 = payload.fotoBase64;
  state.nome = payload.nome;
  state.token = payload.token;
  state.id = payload.id;
  state.papelLista = payload.papelLista;
  state.recursoLista = payload.recursoLista;

  storage.setLocalUsuario(payload)
}

function ActionSetToken(payload) {
  storage.setLocalToken(payload);
  state.token = payload
}

const actions = {
  ActionSetUser: ActionSetUser,
  login: async (credentials: Credential) => {
    credentials.acessToken = "$2b$10$077jneVtRzI/EnFTffYb1.z/BXLF2/yD0zB767K44rEbaP54PjM6u";
    const user = await login(credentials);
    ActionSetToken(user.token)
    ActionSetUser(user)
  },

  logout: () => {
    localStorage.removeItem("token");
    state.nome = ''
    state.token = ''
    state.fotoBase64 = '';
    state.email = '';
    state.papelLista = [];
    state.recursoLista = [];

    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
  },



  ActionCheckToken() {

    const token = storage.getLocalToken();
    if (state.token) {
      return Promise.resolve(state.token);
    } else if (!token) {
      return this.logout()
    } else {
      ActionSetToken(token);
      ActionSetUser(storage.getLocalUsuario()); ''
      return Promise.resolve(token);
    }
  },
};
const getters = {
  getStatusLoggedIn: computed(() => !!state.token),
  getToken: computed(() => state.token),
  getUser: computed(() => state),
};
export { getters, state, actions };
