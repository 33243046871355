import { AxiosResponse } from 'axios'
import { httpPredict } from '../api'

export async function getPredict(type: string, userId: number): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`accessibility`, { type, user_id: String(userId) });
    return data;
}

export async function getGrafico(type: string, userId: number): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`graph`, { type, user_id: String(userId) });
    return data;
}

export async function getQuadrant(userId: number): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`quadrant`, { user_id: String(userId) });
    return data;
}


export async function getInvestigate(type: string, userId: number, interval: string): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`investigative`, { type, user_id: String(userId), interval });
    return data;
}


export async function getDescontPrevist(type: string, userId: number, interval: string): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`discount`, { type, user_id: String(userId), interval });
    return data;
}

export async function InvestigateAll(type: string, interval: string): Promise<any> {
    const { data } = await httpPredict.post<any[], AxiosResponse>(`investigative_all`, { type, interval });
    return data;
}
