<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33334 9.7714C3.33334 9.45499 3.56847 9.19349 3.87353 9.15211L3.95834 9.1464L14.945 9.1469L10.9759 5.19396C10.7313 4.95041 10.7304 4.55468 10.974 4.31008C11.1954 4.08771 11.5425 4.06679 11.7876 4.24781L11.8578 4.30818L16.8995 9.32818C16.9317 9.36028 16.9597 9.39502 16.9835 9.43172C16.9902 9.44276 16.9971 9.45406 17.0035 9.46558C17.0095 9.47546 17.0149 9.48572 17.02 9.49609C17.027 9.51119 17.0339 9.52678 17.0402 9.54269C17.0453 9.55495 17.0495 9.56688 17.0534 9.57891C17.058 9.59382 17.0625 9.60971 17.0663 9.62584C17.0692 9.63711 17.0714 9.64795 17.0734 9.65883C17.0762 9.67502 17.0786 9.69178 17.0802 9.70873C17.0817 9.72167 17.0826 9.73448 17.0831 9.7473C17.0832 9.75508 17.0833 9.76322 17.0833 9.7714L17.083 9.79561C17.0826 9.80788 17.0817 9.82013 17.0805 9.83236L17.0833 9.7714C17.0833 9.81084 17.0797 9.84943 17.0727 9.88684C17.0711 9.89578 17.0692 9.90495 17.067 9.91409C17.0626 9.9329 17.0574 9.95102 17.0515 9.96878C17.0486 9.97761 17.0453 9.98704 17.0416 9.99639C17.0343 10.0152 17.0264 10.0331 17.0177 10.0506C17.0136 10.0588 17.0091 10.0674 17.0043 10.0759C16.9965 10.0898 16.9885 10.103 16.9799 10.1158C16.9739 10.1249 16.9673 10.1344 16.9604 10.1437L16.9549 10.1509C16.9381 10.1729 16.9198 10.1938 16.9003 10.2133L16.8995 10.2139L11.8579 15.2348C11.6133 15.4783 11.2176 15.4775 10.974 15.2329C10.7526 15.0106 10.7331 14.6633 10.9152 14.419L10.9758 14.349L14.9433 10.3969L3.95834 10.3964C3.61317 10.3964 3.33334 10.1166 3.33334 9.7714Z"
      :fill="color || '#868E96'" />
  </svg>
</template>
  
<script lang="ts" setup>

</script>
