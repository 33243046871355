<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M2.33203 15.0268V12.9734C2.33203 11.7601 3.3237 10.7568 4.5487 10.7568C6.66037 10.7568 7.5237 9.26342 6.46203 7.43176C5.85536 6.38176 6.21703 5.01676 7.2787 4.41009L9.29703 3.25509C10.2187 2.70676 11.4087 3.03342 11.957 3.95509L12.0854 4.17676C13.1354 6.00842 14.862 6.00842 15.9237 4.17676L16.052 3.95509C16.6004 3.03342 17.7904 2.70676 18.712 3.25509L20.7304 4.41009C21.792 5.01676 22.1537 6.38176 21.547 7.43176C20.4854 9.26342 21.3487 10.7568 23.4604 10.7568C24.6737 10.7568 25.677 11.7484 25.677 12.9734V15.0268C25.677 16.2401 24.6854 17.2434 23.4604 17.2434C21.3487 17.2434 20.4854 18.7368 21.547 20.5684C22.1537 21.6301 21.792 22.9834 20.7304 23.5901L18.712 24.7451C17.7904 25.2934 16.6004 24.9668 16.052 24.0451L15.9237 23.8234C14.8737 21.9918 13.147 21.9918 12.0854 23.8234L11.957 24.0451C11.4087 24.9668 10.2187 25.2934 9.29703 24.7451L7.2787 23.5901C6.21703 22.9834 5.85536 21.6184 6.46203 20.5684C7.5237 18.7368 6.66037 17.2434 4.5487 17.2434C3.3237 17.2434 2.33203 16.2401 2.33203 15.0268Z" fill="#FF5C00"/>
<path d="M13.9987 17.7916C16.0928 17.7916 17.7904 16.094 17.7904 13.9999C17.7904 11.9058 16.0928 10.2083 13.9987 10.2083C11.9046 10.2083 10.207 11.9058 10.207 13.9999C10.207 16.094 11.9046 17.7916 13.9987 17.7916Z" fill="#FF5C00"/>
</svg>

</template>
<script lang="ts" setup>

</script>
