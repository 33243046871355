<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.42123 4.31686C2.56546 4.17264 2.79115 4.15952 2.95019 4.27753L2.99576 4.31686L6.50016 7.82108L10.0046 4.31686C10.1488 4.17264 10.3745 4.15952 10.5335 4.27753L10.5791 4.31686C10.7233 4.46109 10.7364 4.68678 10.6184 4.84582L10.5791 4.89139L6.78742 8.68305C6.6432 8.82728 6.4175 8.84039 6.25846 8.72239L6.2129 8.68305L2.42123 4.89139C2.26258 4.73274 2.26258 4.47551 2.42123 4.31686Z"
      fill="#212529"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
