import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createBlock(_component_v_window, {
    modelValue: $setup.tab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.tab) = $event))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]))
}