import { reactive, computed } from "vue";




const state = reactive<{ loading: boolean, loadingLogin: boolean }>({
  loading: false,
  loadingLogin: false
});


const actions = {
  ActionSetPreloader(payload: boolean) {
    state.loading = payload;
  },
  time(payload: boolean) {
    state.loadingLogin = true;
    setTimeout(() => {
      state.loadingLogin = payload;
    }, 3000);
},
  timeless() {
    state.loading = true
    setTimeout(() => {
      state.loading = false;
    }, 2000);
}


};
const getters = {
  isLoading: computed(() => state.loading),
  isLoadingLogin: computed(() => state.loadingLogin)
};
export { getters, state, actions };
