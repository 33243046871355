<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.9115 5.155C5.1875 5.155 5.4115 5.379 5.4115 5.655C5.4115 5.931 5.1875 6.155 4.9115 6.155H4.28416C3.20816 6.155 2.3335 7.029 2.3335 8.10433V11.361C2.3335 12.4403 3.21083 13.3177 4.29016 13.3177H11.7168C12.7915 13.3177 13.6668 12.4423 13.6668 11.3677V8.11167C13.6668 7.03233 12.7888 6.155 11.7108 6.155H11.0895C10.8135 6.155 10.5895 5.931 10.5895 5.655C10.5895 5.379 10.8135 5.155 11.0895 5.155H11.7108C13.3408 5.155 14.6668 6.48167 14.6668 8.11167V11.3677C14.6668 12.9943 13.3428 14.3177 11.7168 14.3177H4.29016C2.66016 14.3177 1.3335 12.9917 1.3335 11.361V8.10433C1.3335 6.47767 2.65683 5.155 4.28416 5.155H4.9115ZM8.0007 2C8.2767 2 8.5007 2.224 8.5007 2.5L8.50016 9.31733L9.5907 8.22267C9.78536 8.02667 10.1014 8.02667 10.2974 8.22133C10.4927 8.416 10.4934 8.73267 10.2987 8.92867L8.35919 10.8758C8.34025 10.8953 8.31974 10.9132 8.29786 10.9294L8.3547 10.88C8.33323 10.9018 8.30993 10.9213 8.28516 10.9385C8.27004 10.949 8.25468 10.9584 8.2388 10.967C8.23114 10.9712 8.22311 10.9753 8.21497 10.9792C8.20043 10.9861 8.1855 10.9923 8.17022 10.9978C8.16256 11.0005 8.15492 11.0031 8.14722 11.0054C8.12844 11.0112 8.10913 11.0159 8.08942 11.0195C8.08499 11.0202 8.08094 11.0209 8.07688 11.0215C8.06098 11.024 8.04439 11.0257 8.02758 11.0266C8.01851 11.0271 8.00961 11.0273 8.0007 11.0273L7.97416 11.0253L7.93288 11.0228C7.92994 11.0224 7.927 11.0219 7.92408 11.0215L8.0007 11.0273C7.9709 11.0273 7.94135 11.0247 7.91237 11.0195C7.89231 11.0159 7.87305 11.0113 7.85425 11.0055C7.84674 11.0032 7.83937 11.0007 7.83206 10.9981C7.81674 10.9927 7.80161 10.9864 7.78687 10.9794C7.77793 10.9751 7.76902 10.9706 7.76026 10.9658C7.74795 10.959 7.73607 10.9518 7.72454 10.9441C7.71664 10.9389 7.70873 10.9333 7.70099 10.9275C7.68194 10.9132 7.66398 10.8976 7.6472 10.8808L7.6467 10.88L5.7027 8.92867C5.50803 8.73267 5.5087 8.416 5.70403 8.22133C5.90003 8.02667 6.21603 8.02667 6.4107 8.22267L7.50016 9.31733L7.5007 2.5C7.5007 2.224 7.7247 2 8.0007 2Z"
      fill="none"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
