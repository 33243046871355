<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="32" height="32" rx="3.5" fill="white" stroke="#DEE2E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9085 7C23.0525 7 25.1645 9.153 25.1645 12.357V21.553C25.1645 24.785 23.1175 26.887 19.9495 26.907L12.2565 26.91C9.11251 26.91 6.99951 24.757 6.99951 21.553V12.357C6.99951 9.124 9.04651 7.023 12.2145 7.004L19.9075 7H19.9085ZM19.9085 8.5L12.2195 8.504C9.89151 8.518 8.49951 9.958 8.49951 12.357V21.553C8.49951 23.968 9.90451 25.41 12.2555 25.41L19.9445 25.407C22.2725 25.393 23.6645 23.951 23.6645 21.553V12.357C23.6645 9.942 22.2605 8.5 19.9085 8.5ZM19.7156 20.4737C20.1296 20.4737 20.4656 20.8097 20.4656 21.2237C20.4656 21.6377 20.1296 21.9737 19.7156 21.9737H12.4956C12.0816 21.9737 11.7456 21.6377 11.7456 21.2237C11.7456 20.8097 12.0816 20.4737 12.4956 20.4737H19.7156ZM19.7156 16.2872C20.1296 16.2872 20.4656 16.6232 20.4656 17.0372C20.4656 17.4512 20.1296 17.7872 19.7156 17.7872H12.4956C12.0816 17.7872 11.7456 17.4512 11.7456 17.0372C11.7456 16.6232 12.0816 16.2872 12.4956 16.2872H19.7156ZM15.2503 12.1104C15.6643 12.1104 16.0003 12.4464 16.0003 12.8604C16.0003 13.2744 15.6643 13.6104 15.2503 13.6104H12.4953C12.0813 13.6104 11.7453 13.2744 11.7453 12.8604C11.7453 12.4464 12.0813 12.1104 12.4953 12.1104H15.2503Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
