<template>
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 17.5C23 17.91 22.66 18.25 22.25 18.25H15.25V18.5C15.25 20 14.35 20.5 13.25 20.5H7.25C6.15 20.5 5.25 20 5.25 18.5V18.25H2.25C1.84 18.25 1.5 17.91 1.5 17.5C1.5 17.09 1.84 16.75 2.25 16.75H5.25V16.5C5.25 15 6.15 14.5 7.25 14.5H13.25C14.35 14.5 15.25 15 15.25 16.5V16.75H22.25C22.66 16.75 23 17.09 23 17.5Z" fill="#45505F"/>
<path opacity="0.4" d="M23 6.5C23 6.91 22.66 7.25 22.25 7.25H19.25V7.5C19.25 9 18.35 9.5 17.25 9.5H11.25C10.15 9.5 9.25 9 9.25 7.5V7.25H2.25C1.84 7.25 1.5 6.91 1.5 6.5C1.5 6.09 1.84 5.75 2.25 5.75H9.25V5.5C9.25 4 10.15 3.5 11.25 3.5H17.25C18.35 3.5 19.25 4 19.25 5.5V5.75H22.25C22.66 5.75 23 6.09 23 6.5Z" fill="#45505F"/>
</svg>

</template>
<script lang="ts" setup>

</script>
