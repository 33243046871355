import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25d4c19a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    "align-content": "center",
    class: "responsive-mobile"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "2",
        class: "text-center d-flex flex-column justify-content-center align-center pe-0"
      }, {
        default: _withCtx(() => [
          ($setup.props.prependIcon)
            ? _renderSlot(_ctx.$slots, "prependIcon", { key: 0 }, undefined, true)
            : (_openBlock(), _createBlock($setup["SettingsColorIcon"], {
                key: 1,
                class: "mt-3"
              }))
        ]),
        _: 3 /* FORWARDED */
      }),
      _createVNode(_component_v_col, {
        cols: !$setup.props.addBtn ? '8': '10',
        class: "d-flex align-center ps-0"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, _toDisplayString($setup.props.title && $setup.action ? `Cadastrando novo ${$setup.props.title}` : $setup.props.title), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["cols"]),
      (!$setup.props.addBtn)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            cols: "2",
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["PlusElipseIcon"], {
                class: "mt-3 cursor",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.handleConfirm();})
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["lineIcon"], { class: "mx-auto" })
    ]),
    _: 3 /* FORWARDED */
  }))
}