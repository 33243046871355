import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { Equipamento } from '../models'

const controller = 'equipamentos'

export async function getEquipamentoAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<Equipamento[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getEquipamento(id: string): Promise<Equipamento> {

    const { data } = await httpAnalitcs.get<Equipamento, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postEquipamento(payload: Equipamento): Promise<Equipamento> {

    const { data } = await httpAnalitcs.post<Equipamento, AxiosResponse>(`${controller}`, payload)
    return data;
}
export async function testSSH(payload: Equipamento): Promise<Equipamento> {

    const { data } = await httpAnalitcs.post<Equipamento, AxiosResponse>(`teste-ssh`, payload)
    return data;
}

export async function putEquipamento(payload: Equipamento): Promise<Equipamento> {

    const { data } = await httpAnalitcs.put<Equipamento, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteEquipamento(EquipamentoId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${EquipamentoId}`);

    return status === 204;
}

// export async function patchEquipamento(payload: Equipamento): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }