<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M29.3327 16.0001C29.3327 23.3639 23.3631 29.3334 15.9993 29.3334C8.63555 29.3334 2.66602 23.3639 2.66602 16.0001C2.66602 8.63628 8.63555 2.66675 15.9993 2.66675C23.3631 2.66675 29.3327 8.63628 29.3327 16.0001Z" fill="#00B88D"/>
<path d="M21.7069 17.68C21.7069 17.4266 21.6135 17.1733 21.4135 16.9733L16.7069 12.2666C16.3202 11.88 15.6802 11.88 15.2935 12.2666L10.5869 16.9733C10.2002 17.36 10.2002 18 10.5869 18.3866C10.9735 18.7733 11.6135 18.7733 12.0002 18.3866L16.0002 14.3866L20.0002 18.3866C20.3869 18.7733 21.0269 18.7733 21.4135 18.3866C21.6135 18.2 21.7069 17.9466 21.7069 17.68Z" fill="#00B88D"/>
</svg>


</template>
<script lang="ts" setup>

</script>
