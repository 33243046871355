<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M18.887 2.33325H9.11036C4.8637 2.33325 2.33203 4.86492 2.33203 9.11159V18.8766C2.33203 23.1349 4.8637 25.6666 9.11036 25.6666H18.8754C23.122 25.6666 25.6537 23.1349 25.6537 18.8883V9.11159C25.6654 4.86492 23.1337 2.33325 18.887 2.33325Z" fill="#FF5C00"/>
<path d="M16.6349 14L14.8732 13.3817V9.42667H15.2932C16.2382 9.42667 17.0082 10.255 17.0082 11.27C17.0082 11.7483 17.4049 12.145 17.8832 12.145C18.3616 12.145 18.7582 11.7483 18.7582 11.27C18.7582 9.28667 17.2066 7.67667 15.2932 7.67667H14.8732V7C14.8732 6.52167 14.4766 6.125 13.9982 6.125C13.5199 6.125 13.1232 6.52167 13.1232 7V7.67667H12.3649C10.6382 7.67667 9.22656 9.135 9.22656 10.92C9.22656 13.0083 10.4399 13.6733 11.3616 14L13.1232 14.6183V18.5617H12.7032C11.7582 18.5617 10.9882 17.7333 10.9882 16.7183C10.9882 16.24 10.5916 15.8433 10.1132 15.8433C9.6349 15.8433 9.23823 16.24 9.23823 16.7183C9.23823 18.7017 10.7899 20.3117 12.7032 20.3117H13.1232V21C13.1232 21.4783 13.5199 21.875 13.9982 21.875C14.4766 21.875 14.8732 21.4783 14.8732 21V20.3233H15.6316C17.3582 20.3233 18.7699 18.865 18.7699 17.08C18.7582 14.98 17.5449 14.315 16.6349 14ZM11.9449 12.355C11.3499 12.145 10.9882 11.9467 10.9882 10.9317C10.9882 10.1033 11.6066 9.43833 12.3766 9.43833H13.1349V12.775L11.9449 12.355ZM15.6316 18.5733H14.8732V15.2367L16.0516 15.645C16.6466 15.855 17.0082 16.0533 17.0082 17.0683C17.0082 17.8967 16.3899 18.5733 15.6316 18.5733Z" fill="#FF5C00"/>
</svg>

</template>
<script lang="ts" setup>

</script>
