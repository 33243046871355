<template>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4"
            d="M16.69 2H8.31C4.67 2 2.5 4.17 2.5 7.81V16.18C2.5 19.83 4.67 22 8.31 22H16.68C20.32 22 22.49 19.83 22.49 16.19V7.81C22.5 4.17 20.33 2 16.69 2Z"
            fill="white" />
        <path
            d="M16.5 11.25H13.25V8C13.25 7.59 12.91 7.25 12.5 7.25C12.09 7.25 11.75 7.59 11.75 8V11.25H8.5C8.09 11.25 7.75 11.59 7.75 12C7.75 12.41 8.09 12.75 8.5 12.75H11.75V16C11.75 16.41 12.09 16.75 12.5 16.75C12.91 16.75 13.25 16.41 13.25 16V12.75H16.5C16.91 12.75 17.25 12.41 17.25 12C17.25 11.59 16.91 11.25 16.5 11.25Z"
            fill="white" />
    </svg>
</template>
    <script lang="ts" setup>

  </script>
