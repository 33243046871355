import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'

const controller = 'users'

export async function getUsersAll(): Promise<AxiosResponse> {
  return await httpAnalitcs.get(`${controller}`)
}
export async function zeroTouch(id: string): Promise<any[]> {
  const { data } = await httpAnalitcs.get<any[]>(`util/listagemUsuario?coletorid=${id}`)
  return data;
}
export async function downloadLinuxMac(id: string, coletor: string): Promise<AxiosResponse> {
  const { data } = await httpAnalitcs.get<AxiosResponse>(`coletor-zerotouch?usuarioid=${id}&coletorid=${coletor}`, {
    responseType: 'blob'
  })
  return data;
}
export async function downloadWin(id: string, coletor: string): Promise<AxiosResponse> {
  const { data } = await httpAnalitcs.get<AxiosResponse>(`coletor-zerotouch/instalar-bat-win?usuarioid=${id}&coletorid=${coletor}`, {
    responseType: 'blob'
  })
  return data;
}
export async function getUser(userId: string): Promise<AxiosResponse> {
  return await httpAnalitcs.get(`${controller}/${userId}`)
}

export async function createUser(payload: { name: string }): Promise<AxiosResponse> {
  return await httpAnalitcs.post(`${controller}`, payload)
}

export async function updateUser(payload: { name: string, id: string }): Promise<AxiosResponse> {
  return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
}

export async function deleteUser(userId: string): Promise<AxiosResponse> {
  return await httpAnalitcs.patch(`${controller}/${userId}`)
}
