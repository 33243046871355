<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.42834 1.33337C7.06234 1.33404 7.66634 1.63671 8.04501 2.14271L8.65234 2.95071C8.84501 3.20671 9.15101 3.35937 9.47101 3.36004H11.357C13.9317 3.36004 15.131 4.67937 15.131 7.51204L15.1123 10.8234C15.1117 13.4687 13.4677 15.1127 10.821 15.1127H5.63301C2.98101 15.1127 1.33301 13.468 1.33301 10.8214V5.62204C1.33301 2.77604 2.59901 1.33337 5.09501 1.33337H6.42834ZM6.42767 2.33337H5.09501C3.15901 2.33337 2.33301 3.31737 2.33301 5.62204V10.8214C2.33301 12.944 3.50501 14.1127 5.63301 14.1127H10.821C12.9437 14.1127 14.1123 12.944 14.1123 10.8214V10.8194L14.131 7.50937C14.131 5.24337 13.3537 4.36004 11.357 4.36004H9.47034C8.83767 4.35937 8.23367 4.05737 7.85367 3.55204L7.24501 2.74271C7.05367 2.48604 6.74767 2.33404 6.42767 2.33337ZM11.1438 9.47524C11.4198 9.47524 11.6438 9.69924 11.6438 9.97524C11.6438 10.2512 11.4198 10.4752 11.1438 10.4752H5.32047C5.04447 10.4752 4.82047 10.2512 4.82047 9.97524C4.82047 9.69924 5.04447 9.47524 5.32047 9.47524H11.1438Z"
      fill="none"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
