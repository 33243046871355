import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b9dee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MoleculeCard"], {
    loading: $setup.props.loading,
    "text-tooltip": $setup.props.text,
    "no-divider-footer": "",
    footer: $setup.props.footer,
    title: $props.title,
    elevation: "0",
    headerHeight: "56px",
    headerNoPaddingX: ""
  }, {
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (info, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(['area-cards-info', {'px-0': $setup.state.isMobile}]),
          key: String(index)
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(`d-flex align-center ${$setup.state.isMobile ? 'px-4' : ''}`)
            }, [
              (info.icon)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comps[info.icon]), {
                    key: 0,
                    class: "mr-4"
                  }))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", null, [
                _createVNode($setup["comps"].AtomSubTitle, {
                  "no-margin": "",
                  text: info.description
                }, null, 8 /* PROPS */, ["text"]),
                _createElementVNode("p", _hoisted_2, _toDisplayString(info.value), 1 /* TEXT */)
              ])
            ], 2 /* CLASS */),
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comps.warningIcon), {
                  key: 0,
                  class: "ms-auto"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" <div class=\"d-flex align-center justify-space-between\">\n         <comps.AtomBadge :color=\"info.success ? '#15D095' : '#FF0D21'\" :bgColor=\"info.success ? '#CCFFEE' : '#FFE3E5'\"\n          :text=\"info.variant\">\n          <template #icon>\n            <component :is=\"info.success ? comps.ArrowUpIcon : comps.ArrowDownIcon\" />\n          </template>\n        </comps.AtomBadge>\n      </div> ")
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "text-tooltip", "footer", "title"]))
}