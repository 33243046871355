
import CollerctorsPage from './collectors/CollectorsPage.vue'
import CompanyPage from './company/CompanyPage.vue'
import EquipamentPage from './equipment/EquipamentPage.vue'
import GroupsPage from './groups/GroupsPage.vue'
import InternetLinkPage from './internetLink/InternetLinkPage.vue'
import ProviderPage from './provider/ProviderPage.vue'
import ServicesPage from './services/ServicesPage.vue'
import TypeOfEquipmentPage from './typeEquipment/TypeOfEquipmentPage.vue'
import UnitsPage from './units/UnitsPage.vue'
import CollectorsVersionPage from './collectorsVersion/CollectorsVersionPage.vue'
import TriggersPage from './triggers/TriggersPage.vue'
export {
    CollerctorsPage,
    CompanyPage,
    EquipamentPage,
    GroupsPage,
    InternetLinkPage,
    ProviderPage,
    ServicesPage,
    TypeOfEquipmentPage,
    UnitsPage,
    CollectorsVersionPage,
    TriggersPage
}