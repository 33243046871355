import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7810a5c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_2 = { class: "area-cards-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MoleculeCard"], { title: $props.title }, {
    default: _withCtx(() => [
      ($setup.props.hasToggle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createCommentVNode(" <v-btn-toggle density=\"compact\" v-model=\"toggleBtn\" class=\"area-btns\">\n        <v-btn class=\"btn-text\"> Links </v-btn>\n\n        <v-btn class=\"btn-text\"> Serviços </v-btn>\n      </v-btn-toggle> "),
            _createVNode($setup["AtomToogleButton"], {
              width: "172px",
              buttons: $setup.props.buttons ?? [],
              onHandleToggle: $setup.handleToogleChange
            }, null, 8 /* PROPS */, ["buttons"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (info, index) => {
          return (_openBlock(), _createBlock($setup["AtomInfoCard"], {
            key: String(index),
            number: info.number,
            description: info.description,
            noBorder: info.noBorder,
            hasIcon: !!info.icon
          }, _createSlots({ _: 2 /* DYNAMIC */ }, [
            (info.icon)
              ? {
                  name: "icon",
                  fn: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.mapComp[String(info.order)])))
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["number", "description", "noBorder", "hasIcon"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}