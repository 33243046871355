import { createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44e1fed6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = { class: "d-flex justify-space-between w-100 controls" }
const _hoisted_3 = {
  ref: "areaSaltos",
  class: "area"
}
const _hoisted_4 = { class: "step1 mb-9" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "border rounded-lg card-step pa-4 mt-12" }
const _hoisted_7 = { class: "text-value" }
const _hoisted_8 = { class: "text-value" }
const _hoisted_9 = { class: "text-value" }
const _hoisted_10 = { class: "text-value" }
const _hoisted_11 = { class: "text-value" }
const _hoisted_12 = { class: "text-value" }
const _hoisted_13 = { class: "text-value" }
const _hoisted_14 = { class: "text-value" }
const _hoisted_15 = { class: "text-value" }
const _hoisted_16 = { class: "text-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["AtomButton"], {
        background: "transparent",
        class: "pa-0",
        onMousedown: $setup.handleLeft
      }, {
        default: _withCtx(() => [
          _createVNode($setup["BtnChevronLeft"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["AtomButton"], {
        background: "transparent",
        class: "pa-0",
        onMousedown: $setup.handleRight
      }, {
        default: _withCtx(() => [
          _createVNode($setup["BtnChevronRight"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: String(step.label),
          class: "d-flex flex-column align-center relative",
          style: _normalizeStyle(`flex-shrink: 0; width: ${$setup.widtharea}`)
        }, [
          _createElementVNode("p", {
            style: _normalizeStyle({ color: $props.currentStep === step.label ? '#212529' : '' }),
            class: "title-step"
          }, _toDisplayString(step.label.replace(' ', '(')) + ") ", 5 /* TEXT, STYLE */),
          _createElementVNode("div", _hoisted_4, [
            (step.completed)
              ? (_openBlock(), _createBlock($setup["CompletedStepIcon"], { key: 0 }))
              : ($props.currentStep === step.label)
                ? (_openBlock(), _createBlock($setup["StepActiveIcon"], { key: 1 }))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(index + 1), 1 /* TEXT */))
          ]),
          _createElementVNode("div", {
            style: _normalizeStyle({ background: $props.currentStep === step.label || step.completed ? '#FFDECC' : '' }),
            class: "line-step-l"
          }, null, 4 /* STYLE */),
          _createElementVNode("div", {
            style: _normalizeStyle({ background: step.completed ? '#FFDECC' : '' }),
            class: "line-step-r"
          }, null, 4 /* STYLE */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                    }, "Latência", 2 /* CLASS */),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(step.latencia), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                    }, "Jitter", 2 /* CLASS */),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(step.jitter), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */),
            (step.maxJitter || step.bestLatency)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    (step.maxJitter)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                            }, "Maior Jitter", 2 /* CLASS */),
                            _createElementVNode("p", _hoisted_9, _toDisplayString(step.maxJitter), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (step.bestLatency)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                            }, "Jitter", 2 /* CLASS */),
                            _createElementVNode("p", _hoisted_10, _toDisplayString(step.jitter), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                    }, "Perda de pacote", 2 /* CLASS */),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(step.perda), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */),
            (step.host)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                        }, "Host", 2 /* CLASS */),
                        _createElementVNode("p", _hoisted_12, _toDisplayString(step.host), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (step.ipLocation)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                        }, "Localização IP", 2 /* CLASS */),
                        _createElementVNode("p", _hoisted_13, _toDisplayString(step.ipLocation), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (step.location || step.unitLongitude || step.unitLatitude)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                  default: _withCtx(() => [
                    (step.location)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                            }, "Localização", 2 /* CLASS */),
                            _createElementVNode("p", _hoisted_14, _toDisplayString(step.location), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (step.unitLatitude)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 1,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                            }, "Latitude", 2 /* CLASS */),
                            _createElementVNode("p", _hoisted_15, _toDisplayString(step.unitLatitude), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (step.unitLongitude)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 2,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              class: _normalizeClass(["text-muted", { 'text-sm': $setup.props.isLarge }])
                            }, "Longitude", 2 /* CLASS */),
                            _createElementVNode("p", _hoisted_16, _toDisplayString(step.unitLongitude), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true)
          ])
        ], 4 /* STYLE */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */)
  ]))
}