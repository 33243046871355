<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3082 2C13.7542 2 15.7442 3.99 15.7442 6.436V7.368C15.7442 7.782 15.4082 8.118 14.9942 8.118C14.5802 8.118 14.2442 7.782 14.2442 7.368V6.436C14.2442 4.816 12.9272 3.5 11.3082 3.5H6.43324C4.81624 3.5 3.50024 4.816 3.50024 6.436V17.565C3.50024 19.184 4.81624 20.5 6.43324 20.5H11.3192C12.9312 20.5 14.2442 19.188 14.2442 17.576V16.633C14.2442 16.219 14.5802 15.883 14.9942 15.883C15.4082 15.883 15.7442 16.219 15.7442 16.633V17.576C15.7442 20.016 13.7582 22 11.3192 22H6.43324C3.98924 22 2.00024 20.011 2.00024 17.565V6.436C2.00024 3.99 3.98924 2 6.43324 2H11.3082ZM19.3883 8.554L22.3163 11.469C22.3425 11.4949 22.3659 11.5219 22.3873 11.5504L22.3163 11.469C22.3518 11.5039 22.3833 11.5421 22.4106 11.5828C22.4227 11.6012 22.4343 11.6203 22.445 11.6399C22.4537 11.6552 22.4618 11.6712 22.4692 11.6875C22.4755 11.7018 22.4816 11.7162 22.4872 11.7308C22.4948 11.7498 22.5014 11.7693 22.5071 11.7891C22.5115 11.8047 22.5155 11.8203 22.519 11.836C22.5235 11.8551 22.527 11.8743 22.5298 11.8937C22.5314 11.9063 22.5329 11.9195 22.5341 11.9328C22.5364 11.9556 22.5373 11.9777 22.5373 12L22.5322 12.062L22.5302 12.1017C22.53 12.1034 22.5297 12.1051 22.5295 12.1068L22.5373 12C22.5373 12.0555 22.5312 12.1105 22.5193 12.1639C22.5155 12.1797 22.5115 12.1953 22.507 12.2107C22.5014 12.2307 22.4948 12.2502 22.4874 12.2695C22.4816 12.2838 22.4755 12.2982 22.469 12.3123C22.4618 12.3288 22.4537 12.3448 22.4451 12.3605C22.4343 12.3797 22.4227 12.3988 22.4102 12.4172C22.4032 12.4282 22.3955 12.439 22.3875 12.4497C22.3637 12.481 22.3378 12.5104 22.3097 12.5377L19.3883 15.447C19.2423 15.593 19.0503 15.666 18.8593 15.666C18.6673 15.666 18.4743 15.593 18.3283 15.445C18.0363 15.151 18.0373 14.677 18.3303 14.385L19.9702 12.75H9.74604C9.33204 12.75 8.99604 12.414 8.99604 12C8.99604 11.586 9.33204 11.25 9.74604 11.25H19.9722L18.3303 9.616C18.0373 9.324 18.0353 8.85 18.3283 8.556C18.6203 8.262 19.0943 8.262 19.3883 8.554Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
