import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createCommentVNode(" <button @click=\"Notification.incrementNotification\">Teste nova notificação</button> "),
    _createCommentVNode(" <button @click=\"Notification.setNotification({text: `Novo notificação ${Notification.getNotifications.value.length + 1}`, id: String(Notification.getNotifications.value.length + 1)})\">Teste nova notificação</button> "),
    (!$setup.state.isMobile)
      ? (_openBlock(), _createBlock($setup["MoleculeTabs"], {
          key: 0,
          modelValue: $setup.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.tab) = $event)),
          textTooltip: true,
          tabs: $setup.tabs
        }, null, 8 /* PROPS */, ["modelValue", "tabs"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeTabWindow"], {
      modelValue: $setup.tab,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.tab) = $event)),
      class: _normalizeClass({ border: !$setup.state.isMobile })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["MoleculeTabItem"], { value: "Observabilidade" }, {
          default: _withCtx(() => [
            _createVNode($setup["OverView"], {
              onRealtime: _cache[1] || (_cache[1] = ($event: any) => ($setup.dadosRealtime = $event)),
              onRedirect: _cache[2] || (_cache[2] = ($event: any) => ($setup.tab = 'realtime')),
              roda: $setup.rodarMonitor
            }, null, 8 /* PROPS */, ["roda"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "financeiras" }, {
          default: _withCtx(() => [
            _createVNode($setup["InformacoesFinanceiras"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "realtime" }, {
          default: _withCtx(() => [
            _createVNode($setup["MonitorRealtime"], {
              onRedirect: _cache[3] || (_cache[3] = ($event: any) => ($setup.tab = 'Observabilidade')),
              dadosRealtime: $setup.dadosRealtime,
              onLoad: $setup.handleLoadMonitor
            }, null, 8 /* PROPS */, ["dadosRealtime"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"])
  ]))
}