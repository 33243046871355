<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#FFDECC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8106 6.83362C17.8499 6.83362 17.8885 6.83693 17.9261 6.8433L18.0516 6.84407C18.2386 6.84407 18.4173 6.92015 18.5475 7.0549L23.1904 11.8922C23.3132 12.0196 23.3821 12.191 23.3821 12.3679V20.7701C23.3985 23.07 21.6073 24.9492 19.3037 25.0427L11.953 25.0436H11.8531C9.60728 24.9928 7.80613 23.1765 7.75125 20.9526L7.75098 10.9498C7.80414 8.67557 9.68239 6.84407 11.9401 6.84407L17.695 6.8433C17.7326 6.83693 17.7712 6.83362 17.8106 6.83362ZM17.123 8.2187L11.942 8.21907C10.4231 8.21907 9.16173 9.44923 9.12598 10.9663V20.7701C9.09206 22.3403 10.3296 23.6337 11.8842 23.6686H19.2762C20.8061 23.6053 22.018 22.3339 22.0071 20.7756L22.007 13.2347L20.1646 13.2356C18.4871 13.231 17.1231 11.8634 17.1231 10.1877L17.123 8.2187ZM17.6393 18.391C18.0188 18.391 18.3268 18.699 18.3268 19.0785C18.3268 19.458 18.0188 19.766 17.6393 19.766H12.6893C12.3098 19.766 12.0018 19.458 12.0018 19.0785C12.0018 18.699 12.3098 18.391 12.6893 18.391H17.6393ZM15.7648 14.9517C16.1443 14.9517 16.4523 15.2597 16.4523 15.6392C16.4523 16.0187 16.1443 16.3267 15.7648 16.3267H12.6885C12.309 16.3267 12.001 16.0187 12.001 15.6392C12.001 15.2597 12.309 14.9517 12.6885 14.9517H15.7648ZM18.498 8.98962L18.4981 10.1877C18.4981 11.108 19.247 11.8579 20.1664 11.8606L21.2535 11.8597L18.498 8.98962Z"
      :fill="color || '#FF5C00'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
