<template>
<svg width="54" height="54" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M32.5001 59.1665C45.2689 59.1665 55.6201 48.8153 55.6201 36.0465C55.6201 23.2777 45.2689 12.9265 32.5001 12.9265C19.7312 12.9265 9.38007 23.2777 9.38007 36.0465C9.38007 48.8153 19.7312 59.1665 32.5001 59.1665Z" fill="#697B8E"/>
<path d="M32.5 37.1666C31.4067 37.1666 30.5 36.2599 30.5 35.1666V21.8333C30.5 20.7399 31.4067 19.8333 32.5 19.8333C33.5933 19.8333 34.5 20.7399 34.5 21.8333V35.1666C34.5 36.2599 33.5933 37.1666 32.5 37.1666Z" fill="#697B8E"/>
<path d="M40.2067 9.69992H24.7934C23.7267 9.69992 22.8734 8.84659 22.8734 7.77992C22.8734 6.71325 23.7267 5.83325 24.7934 5.83325H40.2067C41.2734 5.83325 42.1267 6.68659 42.1267 7.75325C42.1267 8.81992 41.2734 9.69992 40.2067 9.69992Z" fill="#697B8E"/>
</svg>



</template>
<script lang="ts" setup>

</script>
