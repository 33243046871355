import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { InternetLink  } from '@/core/models';

const controller = 'internetLinks'

export async function getInternetLinkAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<InternetLink[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getInternetLink(id: string): Promise<InternetLink> {

    const { data } = await httpAnalitcs.get<InternetLink, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postInternetLink(payload: InternetLink): Promise<InternetLink> {

    const { data } = await httpAnalitcs.post<InternetLink, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putInternetLink(payload: InternetLink): Promise<InternetLink> {

    const { data } = await httpAnalitcs.put<InternetLink, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteInternetLink(InternetLinkId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${InternetLinkId}`);

    return status === 204;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }