import { createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["MoleculeTitleSetings"], {
      class: "my-5",
      addBtn: true,
      title: "Coletores em execução",
      prependIcon: ""
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["GraphIconColor"], { class: "mt-2" })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: _normalizeClass(!$setup.state.isMobile ? 'border': '')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTable"], {
              loading: $setup.loading,
              headers: $setup.collectorsInstalledheaders,
              items: $setup.paginationTableDataCollection,
              onBlur: $setup.searching,
              onChangePerPage: _cache[0] || (_cache[0] = (ev) => ($setup.collectorInstalledPerPage = ev)),
              onChangePage: _cache[1] || (_cache[1] = (ev) => ($setup.collectorInstalledPage = ev)),
              "total-items": $setup.collectorsInstalledData.length,
              perPage: $setup.collectorInstalledPerPage,
              page: $setup.collectorInstalledPage,
              filters: true,
              onSearching: $setup.searching,
              "has-options": false,
              noclickrow: true
            }, null, 8 /* PROPS */, ["loading", "headers", "items", "total-items", "perPage", "page"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}