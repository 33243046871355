<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3749 2.00177C15.2949 2.00177 17.6729 4.37977 17.6729 7.30277L17.6728 8.87937C19.448 9.49718 20.725 11.1872 20.725 13.1706V17.4596C20.725 19.9646 18.688 22.0016 16.183 22.0016H8.542C6.037 22.0016 4 19.9646 4 17.4596V13.1706C4 11.1876 5.27652 9.49782 7.05121 8.87972L7.0519 7.30277C7.0579 5.86277 7.6149 4.53377 8.6199 3.53777C9.6259 2.54077 10.9539 1.96377 12.3749 2.00177ZM16.183 10.1286H8.542C6.864 10.1286 5.5 11.4926 5.5 13.1706V17.4596C5.5 19.1376 6.864 20.5016 8.542 20.5016H16.183C17.86 20.5016 19.225 19.1376 19.225 17.4596V13.1706C19.225 11.4926 17.86 10.1286 16.183 10.1286ZM12.3623 13.4544C12.7763 13.4544 13.1123 13.7904 13.1123 14.2044V16.4254C13.1123 16.8394 12.7763 17.1754 12.3623 17.1754C11.9483 17.1754 11.6123 16.8394 11.6123 16.4254V14.2044C11.6123 13.7904 11.9483 13.4544 12.3623 13.4544ZM12.3719 3.50177H12.3559C11.3429 3.50177 10.3939 3.89177 9.6769 4.60277C8.9549 5.31677 8.5559 6.26977 8.5519 7.28577L8.551 8.628H16.172L16.1729 7.30277C16.1729 5.20677 14.4679 3.50177 12.3719 3.50177Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
