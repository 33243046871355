import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { TipoEquipamento } from '../models'

const controller = 'tiposEquipamentos'

export async function getTipoEquipamentoAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<TipoEquipamento[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getTipoEquipamento(id: string): Promise<TipoEquipamento> {

    const { data } = await httpAnalitcs.get<TipoEquipamento, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postTipoEquipamento(payload: TipoEquipamento): Promise<TipoEquipamento> {

    const { data } = await httpAnalitcs.post<TipoEquipamento, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putTipoEquipamento(payload: TipoEquipamento): Promise<TipoEquipamento> {

    const { data } = await httpAnalitcs.put<TipoEquipamento, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteTipoEquipamento(TipoEquipamentoId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${TipoEquipamentoId}`);

    return status === 204;
}

// export async function patchTipoEquipamento(payload: TipoEquipamento): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }