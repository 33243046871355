import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-837f6e72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "area" }
const _hoisted_2 = { class: "me-4" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { class: "d-flex justify-space-between" }
const _hoisted_5 = { class: "font-500 text-sm" }
const _hoisted_6 = { class: "font-500 text-xs text-muted" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AtomUpload"], { onChangeFile: $setup.handleUpload }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.files, (file, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["d-flex pa-4 area-info", [index === 0 ? 'mt-8' : '']]),
        key: String(index)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["PaperIcon"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(file.name), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_6, _toDisplayString(file.size), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                onClick: ($event: any) => ($setup.handleRemoveFile(index))
              }, [
                _createVNode($setup["DeletexIcon"])
              ], 8 /* PROPS */, _hoisted_7)
            ])
          ]),
          _createVNode($setup["AtomProgressBar"], {
            modelValue: file.progressUpload,
            "onUpdate:modelValue": ($event: any) => ((file.progressUpload) = $event)
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
        ])
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}