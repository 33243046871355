<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2819 8.1083C24.5352 5.57664 22.4235 3.46497 19.8918 2.7183C17.9668 2.1583 16.6368 2.20497 15.7152 2.8933C14.6068 3.72164 14.4785 5.21497 14.4785 6.27664V9.18164C14.4785 12.0516 15.7852 13.51 18.3518 13.51H21.7002C22.7502 13.51 24.2552 13.3816 25.0835 12.2733C25.7952 11.3633 25.8535 10.0333 25.2819 8.1083Z" fill="#FF5C00"/>
<path opacity="0.2" d="M22.0613 15.5866C21.758 15.2366 21.3146 15.0383 20.8596 15.0383H16.683C14.6296 15.0383 12.9613 13.3699 12.9613 11.3166V7.13993C12.9613 6.68493 12.763 6.2416 12.413 5.93826C12.0746 5.63493 11.608 5.49493 11.1646 5.55326C8.42297 5.90326 5.90297 7.40826 4.25797 9.6716C2.6013 11.9466 1.99464 14.7233 2.51964 17.4999C3.27797 21.5133 6.4863 24.7216 10.5113 25.4799C11.153 25.6083 11.7946 25.6666 12.4363 25.6666C14.548 25.6666 16.5896 25.0133 18.328 23.7416C20.5913 22.0966 22.0963 19.5766 22.4463 16.8349C22.5046 16.3799 22.3646 15.9249 22.0613 15.5866Z" fill="#FF5C00"/>
</svg>

</template>
<script lang="ts" setup>

</script>
