<template>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M8.6613 13.3465C5.34391 13.3465 2.65463 10.6572 2.65463 7.33986C2.65463 4.02247 5.34391 1.33319 8.6613 1.33319C11.9787 1.33319 14.668 4.02247 14.668 7.33986C14.668 10.6572 11.9787 13.3465 8.6613 13.3465Z" fill="#697B8E"/>
<path d="M1.33866 12.6333C1.55866 12.2267 2.02533 12 2.652 12C3.12533 12 3.532 12.1933 3.772 12.5267C4.012 12.86 4.06533 13.3067 3.91866 13.7533C3.632 14.62 3.132 14.8133 2.85866 14.8467C2.81866 14.8533 2.77866 14.8533 2.732 14.8533C2.43866 14.8533 1.98533 14.7267 1.54533 14.0667C1.192 13.5533 1.12533 13.04 1.33866 12.6333Z" fill="#697B8E"/>
</svg>
</template>
<script lang="ts" setup>

</script>
