<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E2E6E9" />
    <path
      d="M18.687 10.9797C18.8646 11.1572 18.8807 11.435 18.7355 11.6307L18.687 11.6868L14.3742 15.9999L18.687 20.313C18.8646 20.4905 18.8807 20.7683 18.7355 20.9641L18.687 21.0201C18.5095 21.1976 18.2318 21.2138 18.036 21.0686L17.9799 21.0201L13.3133 16.3535C13.1358 16.176 13.1196 15.8982 13.2649 15.7024L13.3133 15.6464L17.9799 10.9797C18.1752 10.7844 18.4918 10.7844 18.687 10.9797Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
