<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="1.41421"
      y1="1"
      x2="10.7349"
      y2="10.3207"
      :stroke="color || '#212529'"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="1.26465"
      y1="10.3206"
      x2="10.5853"
      y2="0.999981"
      :stroke="color || '#212529'"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
