import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { Coletor } from '@/core/models';

const controller = 'coletores'

export async function getColetoresAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {

    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')


    const { data } = await httpAnalitcs.get<Coletor[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getColetores(id: string): Promise<Coletor> {

    const { data } = await httpAnalitcs.get<Coletor, AxiosResponse>(`${controller}/${id}`)
    return data;
}


export async function postColetores(payload: Coletor): Promise<Coletor> {
    const { data } = await httpAnalitcs.post<Coletor, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putColetores(payload: Coletor): Promise<Coletor> {

    const { data } = await httpAnalitcs.put<Coletor, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteColetores(ColetorId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${ColetorId}`);

    return status === 204;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }



export async function getColetoresInstalados(userId: number): Promise<any[]> {

    const { data } = await httpAnalitcs.get(`coletores-instalados/${userId}`);

    return data;
}



export async function downloadVersaoColetor(): Promise<any[]> {

    const { data } = await httpAnalitcs.get(`/versao-coletor/download-ultimo`, {
        responseType: 'blob'
    });

    return data;
}

export async function uploadVersaoColetor(versao: string, file: File, tamanho: string): Promise<any> {

    const form = new FormData();
    form.append("versao", versao);
    form.append("tamanho", tamanho);
    form.append("arquivoJar", file)

    const { data } = await httpAnalitcs.post<any, AxiosResponse>(`versao-coletor`, form, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return data;
}



export async function deleteVersaoColetor(idVersao: number): Promise<string> {
    const { data } = await httpAnalitcs.delete<any, AxiosResponse>(`versao-coletor/${idVersao}`)
    return data;
}



export async function listarVersaoColetor(): Promise<any[]> {
    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`versao-coletor`);
    return data;
}


export async function coletorStatus(host: string, usuario: string, hostStatys: string) {
    const { data } = await httpAnalitcs.post<any, AxiosResponse>(`coletor-status/mudar-status?host=${host}&usuario=${usuario}&status=${hostStatys}`);
    return data;
}
