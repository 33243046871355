<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M24.3013 9.34494L16.6596 3.23161C15.1663 2.04161 12.833 2.02994 11.3513 3.21994L3.70963 9.34494C2.61296 10.2199 1.94796 11.9699 2.1813 13.3466L3.6513 22.1433C3.98963 24.1149 5.8213 25.6666 7.8163 25.6666H20.183C22.1546 25.6666 24.0213 24.0799 24.3596 22.1316L25.8296 13.3349C26.0396 11.9699 25.3746 10.2199 24.3013 9.34494Z" fill="#FF5C00"/>
<path d="M14 21.875C13.5217 21.875 13.125 21.4783 13.125 21V17.5C13.125 17.0217 13.5217 16.625 14 16.625C14.4783 16.625 14.875 17.0217 14.875 17.5V21C14.875 21.4783 14.4783 21.875 14 21.875Z" fill="#FF5C00"/>
</svg>

</template>

<script lang="ts" setup>

</script>
