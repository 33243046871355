// TOKEN
export const getLocalToken = () => localStorage.getItem("token");
export const deleteLocalToken = () => localStorage.removeItem("token");
export const setLocalToken = token => localStorage.setItem("token", token);

// USER
export const getLocalUsuario = () =>
  JSON.parse(localStorage.getItem("usuario"));
export const deleteLocalUsuario = () => localStorage.removeItem("usuario");
export const deleteLocalPlan = () => localStorage.removeItem("plan");
export const setLocalUsuario = usuario =>
  localStorage.setItem("usuario", JSON.stringify(usuario));
