import { createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50a0ba1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-space-between align-center" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "d-flex justify-space-between align-end" }
const _hoisted_4 = {
  key: 1,
  class: "value d-flex align-end"
}
const _hoisted_5 = { class: "not-data" }
const _hoisted_6 = {
  key: 2,
  class: "value align-end"
}
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = {
  style: {"font-size":"15px"},
  class: "me-3"
}
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = {
  style: {"font-size":"12px"},
  class: "me-3"
}
const _hoisted_11 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <div class=\"\"> "),
    _createElementVNode("div", {
      class: _normalizeClass([
      $setup.props.itemDiagnostic.value == 0 ? 'card-no-data' : 'card',
      {
        'w-100': $setup.state.isMobile,
      },
    ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "area-icon me-2",
            style: _normalizeStyle({ backgroundColor: $setup.props.itemDiagnostic.color })
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comps[$setup.props.itemDiagnostic.icon])))
          ], 4 /* STYLE */),
          _createVNode($setup["AtomSubTitle"], {
            "no-margin": "",
            text: $setup.props.itemDiagnostic.item,
            class: "title-card me-3"
          }, null, 8 /* PROPS */, ["text"]),
          _createVNode($setup["AtomTooltip"], {
            text: $setup.props.textTooltip ?? ''
          }, {
            default: _withCtx(() => [
              _createVNode($setup["AlertIcon"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["text"])
        ]),
        (!$setup.state.isMobile)
          ? (_openBlock(), _createBlock($setup["AtomButton"], {
              key: 0,
              background: "transparent",
              class: "pa-0",
              onClick: _withModifiers($setup.handleCLickMore, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode($setup["EyeIcon"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.state.isMobile)
          ? (_openBlock(), _createBlock($setup["AtomButton"], {
              key: 1,
              background: "transparent",
              class: "pa-0",
              onClick: _withModifiers($setup.handleCLickMore, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode($setup["ArrowCircleRightIcon"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        ($setup.props.loading)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.LoaderIcon), {
              key: 0,
              color: $setup.props.loadingColor,
              class: "ma-auto"
            }, null, 8 /* PROPS */, ["color"]))
          : (!$setup.props.itemDiagnostic.doisValores)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString($setup.props.itemDiagnostic.value), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_5, _toDisplayString($setup.props.itemDiagnostic.value == 0 ? 'Não há dados coletados' : ' '), 1 /* TEXT */)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, [
                  _createElementVNode("span", null, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString($setup.props.itemDiagnostic.label[0]), 1 /* TEXT */)
                  ]),
                  _createElementVNode("p", {
                    style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[0]}; font-size: 20px;`),
                    class: "mr-5"
                  }, [
                    _createTextVNode(_toDisplayString($setup.props.itemDiagnostic.value) + " ", 1 /* TEXT */),
                    _createElementVNode("span", {
                      class: "not-data",
                      style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[0]} !important;`)
                    }, _toDisplayString($setup.props.itemDiagnostic.legends[0]), 5 /* TEXT, STYLE */)
                  ], 4 /* STYLE */),
                  _createElementVNode("p", {
                    style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[1]}; font-size: 20px;`)
                  }, [
                    _createTextVNode(_toDisplayString($setup.props.itemDiagnostic.nFloat) + " ", 1 /* TEXT */),
                    _createElementVNode("span", {
                      class: "not-data",
                      style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[1]} !important;`)
                    }, _toDisplayString($setup.props.itemDiagnostic.legends[1]), 5 /* TEXT, STYLE */)
                  ], 4 /* STYLE */)
                ]),
                _createElementVNode("span", _hoisted_9, [
                  _createElementVNode("span", null, [
                    _createElementVNode("p", _hoisted_10, _toDisplayString($setup.props.itemDiagnostic.label[1]), 1 /* TEXT */)
                  ]),
                  ($setup.props.itemDiagnostic.value2)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[0]}; font-size: 20px;`),
                        class: "mr-5"
                      }, [
                        _createTextVNode(_toDisplayString($setup.props.itemDiagnostic.value2) + " ", 1 /* TEXT */),
                        _createElementVNode("span", {
                          class: "not-data",
                          style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[0]} !important;`)
                        }, _toDisplayString($setup.props.itemDiagnostic.legends[0]), 5 /* TEXT, STYLE */)
                      ], 4 /* STYLE */))
                    : _createCommentVNode("v-if", true),
                  ($setup.props.itemDiagnostic.nFloat2)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[1]}; font-size: 20px;`)
                      }, [
                        _createTextVNode(_toDisplayString($setup.props.itemDiagnostic.nFloat2) + " ", 1 /* TEXT */),
                        _createCommentVNode(" {{\n              props.itemDiagnostic.legends[1]\n            }} "),
                        _createElementVNode("span", {
                          class: "not-data",
                          style: _normalizeStyle(`color:${$setup.props.itemDiagnostic.colors[1]} !important;`)
                        }, _toDisplayString($setup.props.itemDiagnostic.legends[1]), 5 /* TEXT, STYLE */)
                      ], 4 /* STYLE */))
                    : _createCommentVNode("v-if", true)
                ])
              ])),
        (!$setup.props.loading && $setup.props.itemDiagnostic.nFloat && !$setup.props.itemDiagnostic.doisValores)
          ? (_openBlock(), _createBlock($setup["comps"].AtomBadge, {
              key: 3,
              color: "#ffffff",
              bgColor: $setup.props.badgeColor || '#000000',
              text: $setup.props.itemDiagnostic.nFloat,
              class: "percent"
            }, {
              default: _withCtx(() => [
                _createCommentVNode(" <template #icon>\n            <component :is=\"props.itemDiagnostic.isPositive ? comps.ArrowUpIcon : comps.ArrowDownIcon\" />\n          </template> ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["bgColor", "text"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (!$setup.props.loading && $setup.props.itemDiagnostic.nFloat && !$setup.props.itemDiagnostic.doisValores)
                ? (_openBlock(), _createBlock($setup["comps"].AtomBadge, {
                    key: 0,
                    color: "#ffffff",
                    bgColor: $setup.props.badgeColor || '#000000',
                    text: $setup.props.itemDiagnostic.nFloat,
                    class: "percent"
                  }, null, 8 /* PROPS */, ["bgColor", "text"]))
                : _createCommentVNode("v-if", true)
            ]))
      ])
    ], 2 /* CLASS */),
    _createCommentVNode(" </div> ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}