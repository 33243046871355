<template>
<svg width="123" height="25" viewBox="0 0 123 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.17902 24.8103C7.75769 24.8103 6.50707 24.6455 5.42519 24.318C4.34331 23.9904 3.43612 23.3889 2.70362 22.5155C1.97112 21.642 1.41926 20.3954 1.04805 18.7775C0.676833 17.1597 0.490234 15.0396 0.490234 12.4173C0.490234 9.94782 0.680801 7.91507 1.06393 6.31905C1.44507 4.72303 2.01479 3.47837 2.76913 2.5811C3.52347 1.68582 4.44654 1.0625 5.53835 0.713123C6.63015 0.363745 7.87679 0.189056 9.27629 0.189056C10.2371 0.189056 11.1721 0.24464 12.0793 0.35382C12.9865 0.463001 13.8281 0.594017 14.6043 0.746869C15.3805 0.899722 16.0514 1.06449 16.6212 1.23918V4.3538C16.25 4.26645 15.7239 4.18507 15.047 4.10765C14.3701 4.03221 13.6038 3.9667 12.7522 3.91112C11.9006 3.85752 11.0152 3.82973 10.0961 3.82973C9.08969 3.82973 8.23213 3.94487 7.52345 4.17316C6.81278 4.40343 6.23908 4.82427 5.80236 5.43568C5.36564 6.04709 5.04803 6.92252 4.8515 8.058C4.65498 9.19546 4.55572 10.6704 4.55572 12.4828C4.55572 14.2753 4.64902 15.7403 4.83364 16.8758C5.01825 18.0133 5.31403 18.8927 5.71899 19.514C6.12395 20.1373 6.67978 20.5681 7.39044 20.8083C8.10111 21.0485 8.99044 21.1696 10.0624 21.1696C11.7021 21.1696 13.046 21.1319 14.0941 21.0545C15.1423 20.979 15.984 20.8956 16.6192 20.8083V23.9229C16.0078 24.0976 15.313 24.2505 14.5368 24.3815C13.7606 24.5125 12.9309 24.6157 12.0455 24.6931C11.1621 24.7726 10.2073 24.8103 9.17902 24.8103Z" fill="white"/>
<path d="M26.4241 24.7788C25.0465 24.7788 23.8554 24.6359 22.851 24.352C21.8445 24.0682 21.0267 23.5878 20.3914 22.9089C19.7582 22.2319 19.2877 21.3128 18.982 20.1555C18.6763 18.9982 18.5234 17.5451 18.5234 15.7962C18.5234 13.9819 18.6763 12.4911 18.982 11.3218C19.2877 10.1526 19.7641 9.23549 20.4073 8.56849C21.0525 7.9015 21.8763 7.44294 22.8827 7.19083C23.8872 6.94071 25.0683 6.81366 26.4241 6.81366C27.8217 6.81366 29.0246 6.95063 30.0311 7.22259C31.0355 7.49654 31.8554 7.96105 32.4906 8.61613C33.1239 9.27122 33.5943 10.1844 33.9 11.3536C34.2057 12.5228 34.3586 14.0037 34.3586 15.7962C34.3586 17.567 34.2117 19.0359 33.9159 20.2052C33.6201 21.3744 33.1556 22.2935 32.5224 22.9585C31.8891 23.6255 31.0693 24.096 30.0628 24.3679C29.0564 24.6419 27.8435 24.7788 26.4241 24.7788ZM26.4241 21.5332C27.1011 21.5332 27.6807 21.4736 28.1611 21.3525C28.6415 21.2334 29.0405 20.9813 29.3581 20.5982C29.6738 20.2171 29.91 19.6374 30.0628 18.8612C30.2157 18.0851 30.2931 17.0528 30.2931 15.7625C30.2931 14.496 30.2157 13.4796 30.0628 12.7134C29.91 11.9491 29.6738 11.3754 29.3581 10.9923C29.0405 10.6112 28.6415 10.3591 28.1611 10.238C27.6807 10.1189 27.1011 10.0573 26.4241 10.0573C25.7691 10.0573 25.1993 10.1169 24.7189 10.238C24.2385 10.3591 23.8455 10.6092 23.5378 10.9923C23.2321 11.3754 22.9979 11.9491 22.8331 12.7134C22.6683 13.4796 22.5869 14.494 22.5869 15.7625C22.5869 17.0528 22.6683 18.0851 22.8331 18.8612C22.9979 19.6374 23.2321 20.2171 23.5378 20.5982C23.8435 20.9813 24.2366 21.2315 24.7189 21.3525C25.1993 21.4736 25.7691 21.5332 26.4241 21.5332Z" fill="white"/>
<path d="M37.3066 24.6137V7.04157H40.6178L41.3721 8.87779C42.0927 8.33188 42.9721 7.83958 44.0103 7.40285C45.0485 6.96613 46.1245 6.74777 47.2401 6.74777C48.6177 6.74777 49.6996 7.02568 50.4857 7.5835C51.2718 8.14131 51.8356 8.88969 52.1731 9.82865C52.5125 10.7676 52.6813 11.7959 52.6813 12.9095V24.6137H48.6158V13.4018C48.6158 12.7686 48.4947 12.2326 48.2545 11.7959C48.0143 11.3592 47.6808 11.0197 47.254 10.7795C46.8272 10.5393 46.3091 10.4182 45.6977 10.4182C45.1299 10.4182 44.5999 10.4738 44.1076 10.583C43.6153 10.6922 43.1508 10.8569 42.7141 11.0753C42.2773 11.2937 41.8287 11.5557 41.3701 11.8614V24.6137H37.3066Z" fill="white"/>
<path d="M56.0898 24.6137V7.04157H59.401L60.1553 8.87779C60.8759 8.33188 61.7553 7.83958 62.7935 7.40285C63.8317 6.96613 64.9077 6.74777 66.0233 6.74777C67.4009 6.74777 68.4828 7.02568 69.2689 7.5835C70.055 8.14131 70.6188 8.88969 70.9563 9.82865C71.2957 10.7676 71.4645 11.7959 71.4645 12.9095V24.6137H67.399V13.4018C67.399 12.7686 67.2779 12.2326 67.0377 11.7959C66.7975 11.3592 66.464 11.0197 66.0372 10.7795C65.6104 10.5393 65.0923 10.4182 64.4809 10.4182C63.9131 10.4182 63.3831 10.4738 62.8908 10.583C62.3985 10.6922 61.934 10.8569 61.4973 11.0753C61.0605 11.2937 60.6119 11.5557 60.1533 11.8614V24.6137H56.0898Z" fill="white"/>
<path d="M82.0543 24.7793C80.1526 24.7793 78.6399 24.5173 77.5144 23.9932C76.3888 23.4691 75.5749 22.556 75.0727 21.2558C74.5705 19.9555 74.3184 18.1471 74.3184 15.8305C74.3184 13.4702 74.5586 11.6399 75.039 10.3397C75.5193 9.03944 76.3174 8.1263 77.433 7.60223C78.5466 7.07816 80.0553 6.81613 81.957 6.81613C83.6404 6.81613 84.9903 6.98486 86.0066 7.32432C87.023 7.66377 87.7654 8.26526 88.2359 9.12679C88.7064 9.99031 88.9406 11.2092 88.9406 12.7814C88.9406 13.895 88.7223 14.7863 88.2855 15.4533C87.8488 16.1203 87.2195 16.5947 86.3997 16.8786C85.5798 17.1625 84.6131 17.3054 83.4975 17.3054H78.3501C78.3938 18.3992 78.5407 19.2667 78.7928 19.9118C79.0429 20.557 79.5034 21.0215 80.1704 21.3054C80.8374 21.5893 81.8141 21.7322 83.1044 21.7322H88.4146V24.1262C87.5173 24.3009 86.5724 24.4538 85.5798 24.5848C84.5833 24.7138 83.4101 24.7793 82.0543 24.7793ZM78.3164 14.8459H83.1024C83.8667 14.8459 84.4305 14.699 84.7898 14.4032C85.1511 14.1074 85.3297 13.5675 85.3297 12.7814C85.3297 12.0171 85.2205 11.4156 85.0022 10.9789C84.7838 10.5422 84.4225 10.2305 83.9203 10.0439C83.4181 9.85929 82.761 9.76599 81.9531 9.76599C81.0359 9.76599 80.3134 9.90892 79.7893 10.1928C79.2652 10.4767 78.8881 10.9848 78.6578 11.7173C78.4315 12.4479 78.3164 13.49 78.3164 14.8459Z" fill="white"/>
<path d="M98.6094 24.8111C97.5613 24.8111 96.5926 24.6742 95.7072 24.4022C94.8218 24.1283 94.0516 23.6598 93.3965 22.9928C92.7415 22.3258 92.2333 21.4146 91.872 20.2553C91.5107 19.098 91.332 17.6449 91.332 15.896C91.332 14.1035 91.5008 12.6186 91.8402 11.4375C92.1797 10.2564 92.664 9.32933 93.2993 8.65042C93.9325 7.97351 94.7027 7.49708 95.6099 7.22512C96.5171 6.95118 97.5494 6.81619 98.7087 6.81619C99.6913 6.81619 100.686 6.86582 101.692 6.96309C102.697 7.06234 103.703 7.241 104.708 7.50502V9.83355H99.9216C98.8734 9.83355 98.0198 9.99831 97.3648 10.3259C96.7097 10.6534 96.2174 11.2549 95.8898 12.1283C95.5623 13.0018 95.3975 14.2583 95.3975 15.898C95.3975 17.5159 95.5623 18.7447 95.8898 19.5863C96.2174 20.428 96.7136 20.9958 97.3806 21.2916C98.0476 21.5873 98.9052 21.7342 99.9533 21.7342H104.97V24.029C104.446 24.1819 103.832 24.3188 103.133 24.4379C102.435 24.559 101.696 24.6503 100.92 24.7159C100.144 24.7794 99.3737 24.8111 98.6094 24.8111Z" fill="white"/>
<path d="M113.687 24.6137C112.419 24.6137 111.381 24.4231 110.573 24.04C109.765 23.6588 109.179 23.0176 108.82 22.1224C108.459 21.2271 108.29 20.0459 108.312 18.5809L108.443 10.3189H105.689V7.69663L108.574 7.04155L109.163 2.12445H112.344V7.04155H116.802V10.3189H112.344V18.5472C112.344 19.1149 112.397 19.5794 112.508 19.9407C112.617 20.302 112.776 20.5859 112.983 20.7923C113.189 21.0008 113.413 21.1477 113.656 21.235C113.896 21.3224 114.148 21.378 114.41 21.3998L116.606 21.6301V24.6137H113.687Z" fill="white"/>
<path d="M119.557 24.6474C119.142 24.6474 118.934 24.4509 118.934 24.0578V20.9412C118.934 20.5263 119.142 20.3179 119.557 20.3179H121.885C122.3 20.3179 122.509 20.5263 122.509 20.9412V24.0558C122.509 24.2742 122.449 24.427 122.328 24.5144C122.207 24.6017 122.06 24.6454 121.885 24.6454H119.557V24.6474Z" fill="white"/>
</svg>

</template>
<script lang="ts" setup>

</script>
