import { computed, ref } from 'vue'

const numberNotifications = ref<number>(0)
const notifications = ref<any[]>([])
const muted = ref<boolean>(false)
const getNumberNotifications = computed(() => {
  return numberNotifications.value
})
const getNotifications = computed(() => {
  return notifications.value
})
const getMutedNotification = computed(() => {
  return muted.value
})
function setNotification(payload: any) {
  notifications.value.unshift(payload)
  incrementNotification()
}
function setMuted(val: boolean) {
  muted.value = val
  setLocalMutedNotification()
}
function incrementNotification() {
  numberNotifications.value = numberNotifications.value + 1
  alertNotigfication()
}
function resetNotification() {
  numberNotifications.value = 0
}

function alertNotigfication() {
  if ("Audio" in window && !muted.value) {
    const audio = new Audio("audionotify.mp3");
    audio.play()
  }
}

function getLocalMutedNotification() {
  let localMuted = localStorage.getItem('mutedNotification')
  if(localMuted){
    localMuted = JSON.parse(localMuted)
  }
  muted.value = !!localMuted
}
function setLocalMutedNotification() {
  localStorage.setItem('mutedNotification', JSON.stringify(muted.value))
}
export { getNumberNotifications, notifications, getNotifications, getMutedNotification, getLocalMutedNotification, setLocalMutedNotification, incrementNotification, alertNotigfication, setNotification, resetNotification, setMuted }

