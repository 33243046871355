<template>
  <h1></h1>
    <!-- <MoleculeTable :items="items" :headers="headers" /> -->

  <!-- <MoleculeUploadInfo /> -->
<!-- <MoleculeTable :items="items" :headers="headers" /> -->

  <!-- <MoleculeUploadInfo /> -->
  <!-- <OrganismsChartSaltos /> -->
</template>

<script lang="ts" setup></script>
