<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="4" fill="#FFEECC"/>
<path d="M11.2313 5.25501C12.2133 4.7104 13.4626 5.06823 14.0113 6.04151L19.261 15.1524C19.3766 15.4244 19.4271 15.6455 19.4415 15.8752C19.4704 16.412 19.2827 16.9337 18.9144 17.3352C18.5461 17.7352 18.0479 17.9714 17.5063 18H6.93476C6.71091 17.9864 6.48706 17.9356 6.27765 17.8569C5.23061 17.4346 4.72514 16.2467 5.15118 15.2168L10.437 6.03507C10.6175 5.71231 10.8919 5.43392 11.2313 5.25501ZM12.2205 13.8636C11.8739 13.8636 11.5851 14.1498 11.5851 14.494C11.5851 14.8368 11.8739 15.1238 12.2205 15.1238C12.5671 15.1238 12.8488 14.8368 12.8488 14.4862C12.8488 14.1434 12.5671 13.8636 12.2205 13.8636ZM12.2205 9.39862C11.8739 9.39862 11.5851 9.67701 11.5851 10.0212V12.0458C11.5851 12.3893 11.8739 12.6763 12.2205 12.6763C12.5671 12.6763 12.8488 12.3893 12.8488 12.0458V10.0212C12.8488 9.67701 12.5671 9.39862 12.2205 9.39862Z" fill="#FFAA00"/>
</svg>


</template>
<script lang="ts" setup>

</script>
