import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de5ccf1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "mb-7" }
const _hoisted_4 = { class: "mb-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Grupos\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de versão dos coletores': $setup.type == 'E' ? 'Editando versão dos coletores' : 'versão dos coletores',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "border"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.paginationTableData,
                  totalItems: $setup.simulateTableData.length,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClickDelete: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleDelete($event))),
                  onItemClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDownload())),
                  textBtn: "Baixar Coletores"
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["DeleteIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createCommentVNode(" <AtomButton background=\"white\" text=\"Gerar relatório mensal\" outlined> </AtomButton> "),
                      _createVNode($setup["AtomButton"], {
                        class: "ms-2",
                        text: "Novo Coletor",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {
                $setup.clear();
                $setup.showModalUpload = true;
              })
                      }),
                      _createVNode($setup["AtomButton"], {
                        class: "ms-2",
                        background: "white",
                        outlined: "",
                        text: "Baixar Coletor",
                        onClick: _withModifiers($setup.handleDownload, ["prevent"])
                      })
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data?.version,
                      placeholder: "Digite aqui...",
                      label: "Versão",
                      modelValue: $setup.data.version,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.version) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomUpload"], {
                      onChangeFile: $setup.handleUpload,
                      accept: ".jar",
                      text: "JAR",
                      preenchido: ""
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      disabled: $setup.type == 'C' && !$setup.data.file ? true: false,
                      center: "text-center",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.handleAddOrUpdate())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro': 'Concluir edição'
                    }, null, 8 /* PROPS */, ["disabled", "text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.addNew = false, $setup.type = '', $setup.data = {} )),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.handleAddOrUpdate,
      onHandleCancel: _cache[9] || (_cache[9] = ($event: any) => ($setup.showModalUpload = false)),
      title: "Cadastro de Versão de Coletores",
      subtitle: 'Preencha todos os campos corretamente para cadastrar uma nova versão de coletor',
      show: $setup.showModalUpload,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Registrar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["AtomInput"], {
                width: "100%",
                required: "",
                msgError: "Campo obrigatório",
                hasError: !$setup.data?.version,
                placeholder: "Versão",
                label: "Versão",
                modelValue: $setup.data.version,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.version) = $event))
              }, null, 8 /* PROPS */, ["hasError", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["AtomUpload"], {
                onChangeFile: $setup.handleUpload,
                accept: ".jar",
                text: "JAR",
                preenchido: ""
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}