// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/bg-login.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.area-dash[data-v-cf13f6c4] {\n  flex: 1;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.login-wrapper[data-v-cf13f6c4] {\n  display: flex;\n  /* max-width: 1080px;\n  max-height: 960px; */\n}\n.bg-login[data-v-cf13f6c4] {\n  /* max-width: 834px; */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  /* max-width: 834px; */\n}\n.dash-title[data-v-cf13f6c4] {\n  margin-top: 60px;\n  max-width: 556px;\n}\n.subtitle[data-v-cf13f6c4] {\n  max-width: 418px;\n}\n.form[data-v-cf13f6c4] {\n  padding: 0 130px;\n}\n/* .container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n} */\n.fill-height[data-v-cf13f6c4] {\n  height: 100vh;\n}\n.login-icon-right[data-v-cf13f6c4]{\n  z-index: 10;\n  position: fixed;\n  top: 0px; \n  right: 0px;\n}\n.login-icon-left[data-v-cf13f6c4] {\n  z-index: 10;\n  position: absolute;\n  bottom: -30px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
