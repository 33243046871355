import { AxiosResponse } from 'axios';
import { httpAnalitcs } from '../api';

export async function qualidadeDisponibilidadePorlink(horas: number, id: number, units: number[]): Promise<any> {
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `qualidadeDisponibilidadePorlink/${horas}/${id}${queryUnits}`,
  );
  return data;
}

export async function qualidadeDisponibilidadePorServico(horas: number, id: number, units: number[]): Promise<any> {
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `qualidadeDisponibilidadePorServico/${horas}/${id}${queryUnits}`,
  );
  return data;
}
export async function consumoCpu(id: number, host: string, user: string, minutos: string): Promise<any> {
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `graficoConsumoCPU-analitico?connserviceid=${id}&usuario=${user}&host=${host}&qtdminutos=${minutos}`,
  );
  return data;
}
export async function consumoInternet(id: number, host: string, user: string, minutos: string): Promise<any> {
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `graficoConsumoInternet-analitico?coletorid=${id}&usuario=${user}&host=${host}&qtdminutos=${minutos}`,
  );
  return data;
}
export async function graficoPontosPorHora(id: number, host: string, user: string, minutos: string): Promise<any> {
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `graficoPontosPorHora-analitico?connserviceid=${id}$&usuario=${user}&host=${host}&qtdminutos=${minutos}`,
  );
  return data;
}
export async function servicosPorStatusConectividade(horas: number, id: number, units: number[]): Promise<any> {
  //https://goconnect-s.ceartico.com/gca/api/v1/servicosPorStatusConectividade/6/187
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `servicosPorStatusConectividade/${horas}/${id}${queryUnits}`,
  );
  return data;
}

export async function linksPorStatusConectividade(horas: number, id: number, units: number[]): Promise<any> {
  // https://goconnect-s.ceartico.com/gca/api/v1/linksPorStatusConectividade/6/187
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `linksPorStatusConectividade/${horas}/${id}${queryUnits}`,
  );
  return data;
}

export async function pontosPorHora(id: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any, AxiosResponse>(`pontosPorHora/${id}`);
  return data;
}

export async function pontosPorHoraGrafico(id: number, units: number[]) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';

  const { data } = await httpAnalitcs.get<any, AxiosResponse>(`graficoPontosPorHora/${id}${queryUnits}`);
  return data;
}

export async function dadosPingMinuto(connServiceId: number, minutes: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  if (connServiceId) {
    const { data } = await httpAnalitcs.get<any, AxiosResponse>(`dadosPingMinuto/${connServiceId}/${minutes}`);
    return data;
  }
}

export async function dadosPingMinutoAnalitico(connServiceId: number, minutes: number, host: string, usuario: string) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  if (connServiceId) {
    const { data } = await httpAnalitcs.get<any, AxiosResponse>(
      `dadosPingMinuto-analitico/${connServiceId}/${minutes}?host=${host}&usuario=${usuario}`,
    );
    return data;
  }
}

export async function graficoPontosPorHoraAnalitico(connServiceId: number, host: string, usuario: string) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  if (connServiceId) {
    const { data } = await httpAnalitcs.get<any, AxiosResponse>(
      `graficoPontosPorHora-analitico?host=${host}&usuario=${usuario}&connserviceId=${connServiceId}`,
    );
    return data;
  }
}

export async function dadosPingHora(connServiceId: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any, AxiosResponse>(`dadosPingHora/${connServiceId}`);
  return data;
}

export async function dadosPingDia(connServiceId: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any, AxiosResponse>(`dadosPingDia/${connServiceId}`);
  return data;
}

export async function dadosCaminhoConectividade(connServiceId: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`dadosCaminhoConectividade/${connServiceId}`);
  return data;
}

export async function graficoQuadranteServico(userId: number, type: string) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteServicoNovo/${userId}?tipo=${type}`);
  return data;
}
//goconnect-s.ceartico.com/gca/api/v1/graficoQuadranteUnidade/552
export async function graficoQuadranteUnidade(userId: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteUnidade/${userId}`);
  return data;
}

export async function graficoQuadranteColetor(userId: number) {
  // https://goconnect-s.ceartico.com/gca/api/v1/graficoQuadranteColetor/552
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteColetor/${userId}`);
  return data;
}

export async function graficoQuadranteMediasServicoMin(userId: number, type?: string, id?: number[]) {
  const query = id && id.length ? `?type=${type}&ids=${id.join(',')}` : '';
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteMediasServicoMin/${userId}${query}`);
  return data;
}
//goconnect-s.ceartico.com/gca/api/v1/graficoQuadranteMediasServicoMin/552

export async function graficoQuadranteMediasServicoHora(userId: number, type?: string, id?: number[]) {
  const query = id && id.length ? `?type=${type}&ids=${id.join(',')}` : '';
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteMediasServicoHora/${userId}${query}`);
  return data;
}
//goconnect-s.ceartico.com/gca/api/v1/graficoQuadranteMediasServicoHora/552

export async function graficoQuadranteMediasServicoDia(userId: number, type?: string, id?: number[]) {
  const query = id && id.length ? `?type=${type}&ids=${id.join(',')}` : '';
  // https://goconnect-s.ceartico.com/gca/api/v1
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadranteMediasServicoDia/${userId}${query}`);
  return data;
}
//goconnect-s.ceartico.com/gca/api/v1/graficoQuadranteMediasServicoDia/552
export async function graficoQuadrantePorTipoServico(userId: number, type?: string, id?: number[]) {
  const query = id && id.length ? `?type=${type}&ids=${id.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoQuadrantePorTipoServico/${userId}${query}`);
  return data;
}

//coletor-saltos/140/492

export async function coletorSaltos(unitId: number, servicoId: number) {
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/coletor-saltos/${unitId}/${servicoId}`);
  return data;
}
export async function perdaPacotesGrafico(userId: number, units: number[]) {
  //https://goconnect-s.ceartico.com/gca/api/v1/perda-pacotes/552
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';

  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/perda-pacotes/${userId}${queryUnits}`);
  return data;
}
export async function graficoConsumoInternet(userId: number, units: number[]) {
  ///https://goconnect-s.ceartico.com/gca/api/v1/graficoConsumoInternet/552?
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';

  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/graficoConsumoInternet/${userId}${queryUnits}`);
  return data;
}

export async function totalFinanceiro(userId: number, units: number[]) {
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/total-financeiro/${userId}${queryUnits}`);
  return data;
}

export async function dadosFinanceiro(internetLinkId: number) {
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/dados-financeiro-link/${internetLinkId}`);
  return data;
}

export async function geolocalizacao(units: number[]) {
  const queryUnits = units && units.length > 0 ? `?unidadeLista=${units.join(',')}` : '';
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/geolocalizacao-unidades${queryUnits}`);
  return data;
}

export async function linksServicosPorStatusConectividade(userId: number, type: string, hora: number = 8) {
  // https://goconnect-s.ceartico.com/gca/api/v1/linksServicosPorStatusConectividade/8/254?tipo=SRV
  const { data } = await httpAnalitcs.get<any[], AxiosResponse>(
    `/linksServicosPorStatusConectividade/${hora}/${userId}?tipo=${type}`,
  );
  return data;
}
