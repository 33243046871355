<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9756 0.000244141C11.0186 0.000244141 11.0608 0.00387495 11.1019 0.0108468L11.237 0.0116441C11.441 0.0116441 11.636 0.0946441 11.778 0.241644L16.844 5.51864C16.977 5.65764 17.0521 5.84464 17.0521 6.03764V15.2276C17.068 17.7366 15.112 19.7756 12.599 19.8656H4.45898C2.01875 19.8132 0.0616802 17.8413 0.00134223 15.4259L0.000976562 4.49064C0.0599766 2.00964 2.10798 0.0116441 4.56998 0.0116441L10.8493 0.0108468C10.8903 0.00387495 10.9325 0.000244141 10.9756 0.000244141ZM10.225 1.51124L4.57298 1.51164C2.91598 1.51164 1.53998 2.85364 1.50098 4.50864V15.2276C1.46498 16.9286 2.80498 18.3286 4.48998 18.3656H12.573C14.242 18.3056 15.563 16.9286 15.552 15.2326L15.552 6.98324L13.5436 6.98424C11.7126 6.97924 10.2256 5.48724 10.2256 3.65924L10.225 1.51124ZM8.14138 7.15914C8.34838 7.15914 8.53588 7.24314 8.67163 7.37889L8.67268 7.37954L11.0187 9.73554C11.3107 10.0295 11.3097 10.5035 11.0167 10.7955C10.7227 11.0875 10.2477 11.0875 9.95568 10.7935L8.89098 9.72424L8.89138 13.9501C8.89138 14.3641 8.55538 14.7001 8.14138 14.7001C7.72738 14.7001 7.39138 14.3641 7.39138 13.9501L7.39098 9.72424L6.32768 10.7935C6.18168 10.9415 5.98868 11.0145 5.79668 11.0145C5.60568 11.0145 5.41368 10.9415 5.26768 10.7955C4.97468 10.5035 4.97268 10.0295 5.26568 9.73554L7.60968 7.37954L7.64313 7.34871C7.65759 7.33583 7.67256 7.32351 7.68799 7.31178L7.60968 7.37954C7.63757 7.35156 7.66759 7.32633 7.69932 7.30388C7.82282 7.21285 7.97586 7.15914 8.14138 7.15914ZM11.725 2.35224L11.7256 3.65924C11.7256 4.66324 12.5416 5.48124 13.5456 5.48424L14.73 5.48324L11.725 2.35224Z"
      :fill="color || '#212529'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
