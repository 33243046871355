<template>

<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.98283 0.594559C7.99007 0.0359858 9.27134 0.402985 9.83421 1.40122L15.2185 10.7458C15.337 11.0247 15.3888 11.2515 15.4036 11.4871C15.4333 12.0376 15.2407 12.5727 14.863 12.9845C14.4853 13.3948 13.9742 13.637 13.4188 13.6663H2.57617C2.34658 13.6524 2.11699 13.6003 1.90221 13.5195C0.828316 13.0865 0.309885 11.868 0.746849 10.8118L6.16816 1.39462C6.35331 1.06358 6.63475 0.778058 6.98283 0.594559ZM7.99748 9.42383C7.64198 9.42383 7.34574 9.71743 7.34574 10.0705C7.34574 10.4221 7.64198 10.7164 7.99748 10.7164C8.35297 10.7164 8.64181 10.4221 8.64181 10.0624C8.64181 9.71082 8.35297 9.42383 7.99748 9.42383ZM7.99748 4.84441C7.64198 4.84441 7.34574 5.12994 7.34574 5.48299V7.55947C7.34574 7.91179 7.64198 8.20612 7.99748 8.20612C8.35297 8.20612 8.64181 7.91179 8.64181 7.55947V5.48299C8.64181 5.12994 8.35297 4.84441 7.99748 4.84441Z" fill="#F43841"/>
</svg>

</template>
<script lang="ts" setup>

</script>
