export const formatNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatLang = (value: number): string => {
    if (Number.isInteger(value)) {
        return new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    } else {
        return new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }
};
