import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../pages/home/homePage.vue";
import LoginPage from "../pages/login/loginPage.vue";
import AgentsPage from "../pages/agents/AgentsPage.vue";
import QuadrantPage from "../pages/quadrant/QuadrantPage.vue";
import FinancialPage from "../pages/financial/FinancialPage.vue";
import UsersPage from "../pages/users/UsersPage.vue";
import RelatoryPage from "../pages/relatory/RelatoryPage.vue";
import InvestigationPage from "../pages/investigation/InvestigationPage.vue";
import InvestigationFinance from "../pages/investigationFinance/InvestigationFinance.vue";
import SettingsPage from "../pages/settings/SettingsPage.vue";
import SupportPage from "../pages/support/SupportPage.vue";
import GastosPage from "../pages/financial/pages/GastosPage.vue";
import MeusDados from '../pages/meusdados/MeusDados.vue';
import OverView from '../pages/home/components/OverView.vue';
import FinanceInfo from '../pages/home/components/InformacoesFinanceiras.vue';
// import MonitorRealTime from '../pages/home/components/MonitorRealtime.vue';
import CollectorInstalledPage from '../pages/agents/pages/collectorInstalled/CollectorInstalledPage.vue';
import JumpCollectorAnaliysisPage from '../pages/agents/pages/jumpCollectorAnalysis/JumpCollectorAnaliysisPage.vue';
import QuadrantPageAnaliysis from '../pages/agents/pages/quadrant/QuadrantPage.vue';


import * as storage from "@/store/modules/authentication/storage"

import { Authentication } from "@/store"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: OverView,
    meta: {
      title: 'Overview',
    },
  },
  {
    path: '/info-finance',
    name: 'financeInfo',
    component: FinanceInfo,
    meta: {
      title: 'Overview',
    },
  },
  // {
  //   path: '/monitor-real-time',
  //   name: 'monitorTempoReal',
  //   component: MonitorRealTime,
  //   meta: {
  //     title: 'Monitor tempo real',
  //   },
  // },
  {
    path: '/meus-dados',
    name: 'MeusDados',
    component: MeusDados,
    meta: {
      title: 'Meus Dados',
    },
  },
  {
    path: '/agentes',
    name: 'Agentes',
    component: AgentsPage,
    meta: {
      title: 'Análises Gerais',
    },
  },
  {
    path: '/quadrante',
    name: 'Quadrante',
    component: QuadrantPage,
    meta: {
      title: 'Quadrante',
    },
  },
  {
    path: '/coletor-salto',
    name: 'jump_collector',
    component: JumpCollectorAnaliysisPage,
    meta: {
      title: 'Análise Coletor Saltos',
    },
  },
  {
    path: '/coletor-execucao',
    name: 'collectorInstalled',
    component: CollectorInstalledPage,
    meta: {
      title: 'Coletores em Execução',
    },
  },
  {
    path: '/quadrante-analises',
    name: 'quadrant',
    component: QuadrantPageAnaliysis,
    meta: {
      title: 'Quadrante',
    },
  },
  {
    path: '/quadrante',
    name: 'Quadrante',
    component: QuadrantPage,
    meta: {
      title: 'Quadrante',
    },
  },
  {
    path: '/quadrante',
    name: 'Quadrante',
    component: QuadrantPage,
    meta: {
      title: 'Quadrante',
    },
  },
  {
    path: '/financeiro',
    name: 'Financeiro',
    component: FinancialPage,
    meta: {
      title: 'Gestão financeira',
    },
  },
  {
    path: '/financeiro/gastos/:mesAno/:id',
    name: 'Gastos',
    component: GastosPage,
    meta: {
      title: 'Gastos Extras',
    },
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    component: UsersPage,
    meta: {
      title: 'Gerenciar usuários',
    },
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    component: RelatoryPage,
    meta: {
      title: 'Relatórios',
    },
  },
  {
    path: '/predicao/:item/:dado',
     name: 'Investigação Predição',
    component: InvestigationPage,
    meta: {
      title: 'Investigação Predição',
    },
  },
  {
    path: '/investigacao/:item/:dado',
    name: 'Investigação',
    component: InvestigationPage,
    meta: {
      title: 'Investigação',
    },
  },
  {
    path: '/investigacao-financeiro',
    name: 'Investigação Financeiro',
    component: InvestigationFinance,
    meta: {
      title: 'Investigação - Informações financeiras',
    },
  },
  {
    path: '/configuracoes',
    name: 'Configurações',
    component: SettingsPage,
    meta: {
      title: 'Configurações',
    },
  },
  {
    path: '/suporte',
    name: 'Suporte',
    component: SupportPage,
    meta: {
      title: 'Suporte',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = storage.getLocalToken()
  if (token) {
    Authentication.actions.ActionCheckToken();
  }
  if (!token && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (token && to.path == "/") {
    next("/home")
  } else if (to.path != "/home" && to.path != '/') {
    if (token /* && Authentication.state.recursoLista?.find(f => to.path.includes(f.caminho))*/) {
      next(true)
    } else {
      if (to.path == '/meus-dados') next(true)
      next("/home")
    }
  }


  {
    next(true);
  }



});

export default router;
