<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.1601 2.33337C7.38159 2.33337 7.56464 2.49796 7.59361 2.71151L7.5976 2.77087L7.59725 10.4615L10.3643 7.68313C10.5348 7.51191 10.8118 7.51131 10.983 7.6818C11.1387 7.83679 11.1533 8.07981 11.0266 8.25136L10.9844 8.30052L7.47036 11.8297C7.44789 11.8523 7.42357 11.8719 7.39788 11.8885C7.39015 11.8932 7.38224 11.898 7.37417 11.9025C7.36726 11.9067 7.36008 11.9105 7.35282 11.914C7.34225 11.9189 7.33134 11.9238 7.3202 11.9282C7.31161 11.9317 7.30326 11.9347 7.29485 11.9374C7.28441 11.9406 7.27329 11.9438 7.26199 11.9464C7.25411 11.9484 7.24652 11.95 7.2389 11.9514C7.22757 11.9534 7.21584 11.955 7.20397 11.9562C7.19492 11.9572 7.18595 11.9578 7.17697 11.9582C7.17152 11.9583 7.16583 11.9584 7.1601 11.9584L7.14315 11.9582C7.13457 11.9578 7.12599 11.9572 7.11743 11.9564L7.1601 11.9584C7.13249 11.9584 7.10548 11.9558 7.07929 11.9509C7.07304 11.9498 7.06662 11.9484 7.06022 11.9469C7.04705 11.9438 7.03437 11.9402 7.02193 11.9361C7.01576 11.9341 7.00916 11.9317 7.00261 11.9292C6.98944 11.9241 6.97689 11.9185 6.96467 11.9124C6.95892 11.9096 6.9529 11.9064 6.94695 11.9031C6.93723 11.8976 6.92799 11.892 6.91899 11.886C6.91262 11.8818 6.90599 11.8771 6.89947 11.8723L6.89442 11.8685C6.87903 11.8567 6.86443 11.8439 6.85074 11.8302L6.85034 11.8297L3.33575 8.30054C3.16525 8.12933 3.16583 7.85232 3.33703 7.68182C3.49268 7.52682 3.73576 7.51321 3.90677 7.64064L3.95575 7.68311L6.72225 10.4604L6.7226 2.77087C6.7226 2.52925 6.91848 2.33337 7.1601 2.33337Z"
      :fill="color || '#FF0D21'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
