import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-wapper d-flex ms-3", { error: $props.hasError }]),
      style: {"width":"150px"}
    }, [
      ($props.prependIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([{ 'bg-gray-06': !$props.noBgPrependIcon, 'border-e': !$props.noBorderPrependIcon, error: $props.hasError }, "prependIcon h-100 px-2 d-flex align-center"])
          }, [
            _renderSlot(_ctx.$slots, "prependIcon")
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_VueDatePicker, {
        locale: "pt-BR",
        modelValue: $setup.month,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.month) = $event)),
        onCleared: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('clean'))),
        "month-picker": "",
        "auto-apply": ""
      }, null, 8 /* PROPS */, ["modelValue"])
    ], 2 /* CLASS */),
    ($props.hasError && $props.msgError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString($props.msgError), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}