<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8338 13.6264C14.1098 13.6264 14.3338 13.8504 14.3338 14.1264C14.3338 14.4024 14.1098 14.6264 13.8338 14.6264H8.99848C8.72248 14.6264 8.49848 14.4024 8.49848 14.1264C8.49848 13.8504 8.72248 13.6264 8.99848 13.6264H13.8338ZM10.7443 2.43576C10.7777 2.46176 11.893 3.32843 11.893 3.32843C12.2983 3.56976 12.615 4.0011 12.735 4.51176C12.8543 5.0171 12.7677 5.53843 12.4897 5.97909C12.4878 5.98203 12.486 5.98494 12.4795 5.99358L12.4745 6.00025C12.4294 6.05972 12.2332 6.30776 11.2432 7.54815C11.234 7.56441 11.2236 7.57963 11.2122 7.59443C11.1955 7.61624 11.1773 7.63628 11.158 7.65453C11.0904 7.73956 11.0191 7.8289 10.9441 7.92283L10.7922 8.11313C10.4786 8.50579 10.1067 8.97139 9.66553 9.52364L9.43911 9.80705C8.58733 10.8732 7.49645 12.2382 6.09901 13.9864C5.79301 14.3678 5.33435 14.5898 4.84168 14.5958L2.41568 14.6264H2.40901C2.17768 14.6264 1.97635 14.4678 1.92235 14.2418L1.37635 11.9278C1.26368 11.4484 1.37568 10.9538 1.68301 10.5698L7.96301 2.7151C7.96568 2.71243 7.96768 2.7091 7.97035 2.70643C8.65901 1.8831 9.90435 1.76176 10.7443 2.43576ZM7.26283 5.19133L2.46368 11.1944C2.34968 11.3371 2.30768 11.5211 2.34968 11.6978L2.80368 13.6211L4.82968 13.5958C5.02235 13.5938 5.20035 13.5078 5.31835 13.3611C5.926 12.6008 6.68972 11.6452 7.47492 10.6627L7.75272 10.315L8.03099 9.96681C8.76738 9.04526 9.49487 8.13471 10.1036 7.37258L7.26283 5.19133ZM8.74035 3.34443L7.8875 4.41L10.728 6.59062C11.2748 5.9058 11.6344 5.45476 11.6677 5.41176C11.777 5.23443 11.8197 4.98376 11.7623 4.74243C11.7037 4.4951 11.5497 4.28509 11.3277 4.15109C11.2803 4.11843 10.157 3.24643 10.1223 3.21909C9.69968 2.88043 9.08301 2.9391 8.74035 3.34443Z"
      fill="none"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
