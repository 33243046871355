import { createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ccec523"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex align-center",
  style: {"gap":"5px","max-width":"204px"}
}
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "d-flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AtomTitle"], { title: $setup.text }, null, 8 /* PROPS */, ["title"]),
    _createVNode($setup["AtomSubTitle"], {
      text: `Referentes à ${$setup.mesAno}`
    }, null, 8 /* PROPS */, ["text"]),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "border"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTable"], {
              onChangePerPage: _cache[2] || (_cache[2] = (ev) => ($setup.perPage = ev)),
              onChangePage: _cache[3] || (_cache[3] = (ev) => ($setup.page = ev)),
              filters: true,
              headers: $setup.headersExtras,
              items: $setup.items,
              perPage: $setup.perPage,
              page: $setup.page,
              totalItems: $setup.items.length,
              "text-empty": "Não há gastos para esse período"
            }, {
              actions: _withCtx(({ props }) => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode($setup["AtomButton"], {
                    background: "white",
                    radius: "",
                    class: "pa-0",
                    onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["EditSquareIcon"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                  _createVNode($setup["AtomButton"], {
                    background: "white",
                    radius: "",
                    class: "pa-0",
                    onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["DeleteIcon"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                ])
              ]),
              areaFilter: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode($setup["AtomButton"], {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {
              $setup.gastoSelected = {};
            $setup.showAddGastos = true;
            }),
                    class: "ms-2",
                    text: "Novo gasto"
                  }),
                  _createVNode($setup["AtomButton"], {
                    class: "ms-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.goFinantial())),
                    background: "white",
                    outlined: "",
                    text: "Voltar"
                  })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["headers", "items", "perPage", "page", "totalItems"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.handleAddOrUpdateGasto,
      onHandleCancel: _cache[7] || (_cache[7] = ($event: any) => ($setup.showAddGastos = false)),
      title: `${$setup.gastoSelected?.id ? 'Editar ' : 'Novo '} Gasto Extra`,
      subtitle: "Preencha todos os campos para validar o cadastro do gasto",
      el: "form",
      show: $setup.showAddGastos,
      btnCloseText: "Fechar",
      btnConfirmText: "Salvar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode($setup["AtomInput"], {
              required: "",
              msgError: "Campo obrigatório",
              hasError: !$setup.gastoSelected.motivo,
              label: "Motivo",
              prependIcon: "",
              modelValue: $setup.gastoSelected.motivo,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.gastoSelected.motivo) = $event)),
              class: "mb-5"
            }, {
              prependIcon: _withCtx(() => [
                _createVNode($setup["EditIcon"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["hasError", "modelValue"]),
            _createVNode($setup["AtomInputMoney"], {
              required: "",
              hasError: !Number($setup.gastoSelected.valor),
              msgError: "Campo obrigatório",
              label: "Valor",
              prependIcon: "",
              modelValue: $setup.gastoSelected.valor,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.gastoSelected.valor) = $event)),
              class: "mb-5"
            }, {
              prependIcon: _withCtx(() => [
                _createTextVNode(" R$ ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["hasError", "modelValue"]),
            _createVNode($setup["AtomInput"], {
              required: "",
              hasError: !$setup.gastoSelected.data,
              msgError: "Campo obrigatório",
              prependIcon: "",
              type: "date",
              label: "Data",
              modelValue: $setup.gastoSelected.data,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.gastoSelected.data) = $event)),
              class: "mb-5",
              max: $setup.format($setup.maxDate, 'yyyy-MM-dd'),
              min: $setup.format($setup.minDate, 'yyyy-MM-dd')
            }, {
              prependIcon: _withCtx(() => [
                _createVNode($setup["CalendarIcon"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["hasError", "modelValue", "max", "min"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "show"])
  ], 64 /* STABLE_FRAGMENT */))
}