<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.2904 3.33398C23.362 3.33398 23.4322 3.34001 23.5005 3.35158L23.7278 3.35282C24.0678 3.35282 24.3928 3.49115 24.6294 3.73615L33.0728 12.5345C33.2944 12.7662 33.4194 13.0778 33.4194 13.3995V28.8995C33.4261 33.0145 30.0828 36.3995 25.9678 36.4428L12.3328 36.4445C8.2111 36.3728 4.9311 32.9778 5.0011 28.8778V10.8178C5.0961 6.68282 8.5111 3.35282 12.6161 3.35282L23.0804 3.35158C23.1487 3.34001 23.2189 3.33398 23.2904 3.33398ZM22.04 5.85232L12.6211 5.85282C9.85777 5.85282 7.5661 8.08948 7.5011 10.8478V28.8995C7.45277 31.6445 9.6311 33.8978 12.3561 33.9445L12.6011 33.9428H25.9544C28.6911 33.9128 30.9228 31.6512 30.9194 28.9012L30.9183 14.9723L27.5721 14.974C24.5204 14.9657 22.0404 12.479 22.0404 9.43232L22.04 5.85232ZM18.9069 16.1905C19.5969 16.1905 20.1569 16.7505 20.1569 17.4405L20.1567 20.2723L22.9889 20.2735C23.6789 20.2735 24.2389 20.8335 24.2389 21.5235C24.2389 22.2135 23.6789 22.7735 22.9889 22.7735L20.1567 22.7723L20.1569 25.6072C20.1569 26.2972 19.5969 26.8572 18.9069 26.8572C18.2169 26.8572 17.6569 26.2972 17.6569 25.6072L17.6567 22.7723L14.8239 22.7735C14.1339 22.7735 13.5739 22.2135 13.5739 21.5235C13.5739 20.8335 14.1339 20.2735 14.8239 20.2735L17.6567 20.2723L17.6569 17.4405C17.6569 16.7505 18.2169 16.1905 18.9069 16.1905ZM24.54 7.25398L24.5404 9.43232C24.5404 11.1057 25.9021 12.469 27.5754 12.474L29.5467 12.4723L24.54 7.25398Z"
      :fill="color || '#FF5C00'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
