<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.003 4.0005C16.139 4.0035 19.853 6.9025 21.939 11.7565C22.021 11.9455 22.021 12.1595 21.939 12.3485C19.854 17.2035 16.139 20.1025 12.003 20.1055H11.997C7.86099 20.1025 4.14699 17.2035 2.06099 12.3485C1.97999 12.1595 1.97999 11.9455 2.06099 11.7565C4.14699 6.9025 7.86199 4.0035 11.997 4.0005H12.003ZM12 5.5005C8.56399 5.5015 5.42999 7.9445 3.56999 12.0525C5.42999 16.1615 8.56299 18.6045 12 18.6055C15.437 18.6045 18.57 16.1615 20.43 12.0525C18.57 7.9445 15.437 5.5015 12 5.5005ZM11.9997 8.1413C14.1567 8.1413 15.9117 9.8963 15.9117 12.0533C15.9117 14.2093 14.1567 15.9633 11.9997 15.9633C9.84269 15.9633 8.08869 14.2093 8.08869 12.0533C8.08869 9.8963 9.84269 8.1413 11.9997 8.1413ZM11.9997 9.6413C10.6697 9.6413 9.58869 10.7233 9.58869 12.0533C9.58869 13.3823 10.6697 14.4633 11.9997 14.4633C13.3297 14.4633 14.4117 13.3823 14.4117 12.0533C14.4117 10.7233 13.3297 9.6413 11.9997 9.6413Z"
      :fill="color || '#CED4DA'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
