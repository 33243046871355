<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4639 23.4305C14.9387 23.9594 15.5483 24.25 16.1808 24.25H16.1817C16.817 24.25 17.4293 23.9594 17.905 23.4295C18.1599 23.1481 18.5944 23.1252 18.8758 23.3791C19.1581 23.633 19.181 24.0685 18.9271 24.3499C18.1865 25.1721 17.212 25.625 16.1817 25.625H16.1799C15.1523 25.624 14.1797 25.1712 13.4418 24.349C13.1879 24.0675 13.2108 23.6321 13.4931 23.3791C13.7755 23.1243 14.21 23.1472 14.4639 23.4305ZM16.2264 5.91663C20.301 5.91663 23.0382 9.09013 23.0382 12.0537C23.0382 13.5781 23.4259 14.2244 23.8375 14.91C24.2445 15.5865 24.7056 16.3547 24.7056 17.8067C24.3857 21.5165 20.5128 21.819 16.2264 21.819C11.9401 21.819 8.06627 21.5165 7.75001 17.8654C7.74727 16.3547 8.20836 15.5865 8.61536 14.91L8.75904 14.6682C9.11281 14.0602 9.41469 13.3988 9.41469 12.0537C9.41469 9.09013 12.1519 5.91663 16.2264 5.91663ZM16.2264 7.29163C13.0227 7.29163 10.7897 9.80146 10.7897 12.0537C10.7897 13.9595 10.2608 14.8404 9.79327 15.6177C9.41836 16.242 9.12227 16.7351 9.12227 17.8067C9.27536 19.5355 10.4166 20.444 16.2264 20.444C22.0042 20.444 23.1812 19.4952 23.3334 17.7471C23.3306 16.7351 23.0345 16.242 22.6596 15.6177C22.1921 14.8404 21.6632 13.9595 21.6632 12.0537C21.6632 9.80146 19.4302 7.29163 16.2264 7.29163Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E2E6E9" />
  </svg>
</template>
<script lang="ts" setup>

</script>
