import { AxiosResponse } from 'axios'
import { httpAnalitcs, httpAnalitcsVelocimeter } from '../api'

const controller = 'monitor-tempo-real'

export async function getTempoReal(userId: number, type: string): Promise<any> {


    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`${controller}/${userId}/${type}`);
    return data;
}


export async function getTempoRealColetor(): Promise<any> {


    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`coletor-status/listar`);
    return data;
}

export async function getTempoRealUsuarios(userId: number): Promise<any> {


    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`${controller}/quantidade-usuarios-online/${userId}`);
    return data;
}
export async function Download(): Promise<Blob> {
    const response: AxiosResponse = await httpAnalitcsVelocimeter.get(`speedtest/download`, {
        responseType: 'blob' // Isso garante que o retorno será um Blob.
    });

    return response.data as Blob;
}
export async function Upload(tempFile: any): Promise<any> {

    const { data } = await httpAnalitcsVelocimeter.post<any[], AxiosResponse>(`speedtest/upload`, tempFile, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return data;
}


