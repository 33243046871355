<template>
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31Z"
      fill="#FFEFE6" />
    <path opacity="0.4"
      d="M45.0532 37.7292C44.5857 41.4975 41.4974 44.5858 37.7291 45.0533C35.4482 45.3367 33.3232 44.7133 31.6657 43.495C30.7166 42.8008 30.9432 41.3275 32.0766 40.9875C36.3407 39.6983 39.6982 36.3267 41.0016 32.0625C41.3416 30.9433 42.8149 30.7167 43.5091 31.6517C44.7132 33.3233 45.3366 35.4483 45.0532 37.7292Z"
      fill="#FF5C00" />
    <path
      d="M28.1524 16.8333C21.9049 16.8333 16.8333 21.9049 16.8333 28.1524C16.8333 34.3999 21.9049 39.4716 28.1524 39.4716C34.3999 39.4716 39.4716 34.3999 39.4716 28.1524C39.4574 21.9049 34.3999 16.8333 28.1524 16.8333ZM26.8208 26.5658L30.2349 27.7558C31.4674 28.1949 32.0624 29.0591 32.0624 30.3908C32.0624 31.9208 30.8441 33.1816 29.3566 33.1816H29.2291V33.2524C29.2291 33.8333 28.7474 34.3149 28.1666 34.3149C27.5858 34.3149 27.1041 33.8333 27.1041 33.2524V33.1674C25.5316 33.0966 24.2708 31.7791 24.2708 30.1358C24.2708 29.5549 24.7524 29.0733 25.3333 29.0733C25.9141 29.0733 26.3958 29.5549 26.3958 30.1358C26.3958 30.6458 26.7641 31.0566 27.2174 31.0566H29.3424C29.6683 31.0566 29.9233 30.7591 29.9233 30.3908C29.9233 29.8949 29.8383 29.8666 29.5124 29.7533L26.0983 28.5633C24.8799 28.1383 24.2708 27.2741 24.2708 25.9283C24.2708 24.3983 25.4891 23.1374 26.9766 23.1374H27.1041V23.0808C27.1041 22.4999 27.5858 22.0183 28.1666 22.0183C28.7474 22.0183 29.2291 22.4999 29.2291 23.0808V23.1658C30.8016 23.2366 32.0624 24.5541 32.0624 26.1974C32.0624 26.7783 31.5808 27.2599 30.9999 27.2599C30.4191 27.2599 29.9374 26.7783 29.9374 26.1974C29.9374 25.6874 29.5691 25.2766 29.1158 25.2766H26.9908C26.6649 25.2766 26.4099 25.5741 26.4099 25.9424C26.3958 26.4241 26.4808 26.4524 26.8208 26.5658Z"
      fill="#FF5C00" />
  </svg>
</template>
<script lang="ts" setup>

</script>
