<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M21.2727 14.7351C21.6857 14.7661 21.9947 15.1271 21.9637 15.5401L21.7737 18.0491C21.6167 20.1191 19.8697 21.7401 17.7947 21.7401H6.1947C4.1197 21.7401 2.3727 20.1191 2.2157 18.0491L2.0257 15.5401C1.9947 15.1271 2.3047 14.7661 2.7177 14.7351C3.1327 14.7201 3.4907 15.0131 3.5227 15.4271L3.7117 17.9351C3.8097 19.2271 4.8997 20.2401 6.1947 20.2401H17.7947C19.0897 20.2401 20.1807 19.2271 20.2777 17.9351L20.4677 15.4271C20.4997 15.0131 20.8667 14.7191 21.2727 14.7351ZM13.2849 2C14.7878 2 16.033 3.12626 16.2205 4.57903L18.19 4.5799C20.286 4.5799 21.99 6.2889 21.99 8.3909V11.8299C21.99 12.0969 21.848 12.3429 21.619 12.4769C19.1507 13.9224 16.0239 14.7655 12.7445 14.878L12.7449 16.6766C12.7449 17.0906 12.4089 17.4266 11.9949 17.4266C11.5809 17.4266 11.2449 17.0906 11.2449 16.6766L11.2443 14.8782C7.96818 14.7668 4.84116 13.9235 2.371 12.4769C2.141 12.3429 2 12.0969 2 11.8299V8.3809C2 6.2849 3.709 4.5799 5.81 4.5799L7.76931 4.57903C7.95684 3.12626 9.20196 2 10.7049 2H13.2849ZM18.19 6.0799H5.81C4.536 6.0799 3.5 7.1119 3.5 8.3809V11.3929C5.87371 12.6827 8.86623 13.3895 11.981 13.3909L11.9949 13.3896L12.006 13.39L12.4822 13.385C15.428 13.3149 18.2381 12.616 20.49 11.3929V8.3909C20.49 7.1159 19.459 6.0799 18.19 6.0799ZM13.2849 3.5H10.7049C10.0316 3.5 9.46316 3.95827 9.29525 4.57928H14.6945C14.5266 3.95827 13.9582 3.5 13.2849 3.5Z"
      :fill="color || '#212529'" />
  </svg>
</template>
<script lang="ts" setup>

</script>
