<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#FFEECC" />
    <path
      d="M22.4625 10.51C24.4266 9.42081 26.9251 10.1365 28.0227 12.083L38.522 30.3049C38.7531 30.8488 38.8542 31.291 38.8831 31.7505C38.9409 32.824 38.5654 33.8674 37.8288 34.6703C37.0923 35.4704 36.0958 35.9427 35.0126 36H13.8695C13.4218 35.9728 12.9741 35.8712 12.5553 35.7137C10.4612 34.8693 9.45027 32.4933 10.3024 30.4337L20.8739 12.0701C21.235 11.4246 21.7837 10.8678 22.4625 10.51ZM24.4411 27.7271C23.7479 27.7271 23.1702 28.2996 23.1702 28.9881C23.1702 29.6737 23.7479 30.2476 24.4411 30.2476C25.1343 30.2476 25.6975 29.6737 25.6975 28.9723C25.6975 28.2867 25.1343 27.7271 24.4411 27.7271ZM24.4411 18.7972C23.7479 18.7972 23.1702 19.354 23.1702 20.0425V24.0916C23.1702 24.7786 23.7479 25.3526 24.4411 25.3526C25.1343 25.3526 25.6975 24.7786 25.6975 24.0916V20.0425C25.6975 19.354 25.1343 18.7972 24.4411 18.7972Z"
      fill="#FFAA00" />
  </svg>
</template>
<script lang="ts" setup>

</script>
