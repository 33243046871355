<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="4" fill="#96FABB"/>
<path d="M12.4281 4.57467L17.4061 6.24489C17.9634 6.43096 18.3401 6.94283 18.3432 7.51649L18.3749 12.497C18.3847 14.0069 17.8343 15.4712 16.8263 16.6185C16.3627 17.145 15.7684 17.5973 15.0096 18.0018L12.3337 19.4323C12.2499 19.4764 12.1578 19.4992 12.0649 19.5C11.972 19.5007 11.8791 19.4786 11.7961 19.4352L9.09526 18.0379C8.32813 17.64 7.72862 17.1943 7.26048 16.6751C6.23587 15.5395 5.66656 14.0819 5.65675 12.5698L5.62502 7.59297C5.62202 7.01858 5.99199 6.50303 6.5462 6.30961L11.5054 4.57982C11.7999 4.47539 12.1283 4.47318 12.4281 4.57467ZM14.8086 9.91399C14.5859 9.69997 14.2272 9.70145 14.0075 9.9184L11.4811 12.4086L10.4467 11.4143C10.2239 11.2003 9.86602 11.2025 9.64555 11.4195C9.42583 11.6364 9.42809 11.985 9.65083 12.199L11.0877 13.5817C11.1994 13.6891 11.3444 13.742 11.4894 13.7406C11.6344 13.7398 11.7786 13.6854 11.8888 13.5765L14.8131 10.6936C15.0329 10.4766 15.0306 10.128 14.8086 9.91399Z" fill="#00B88D"/>
</svg>



</template>
<script lang="ts" setup>

</script>
