import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    style: _normalizeStyle({ background: $props.bgColor, color: $props.color }),
    class: "badge"
  }, [
    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
    _createTextVNode(" " + _toDisplayString($setup.props.text), 1 /* TEXT */)
  ], 4 /* STYLE */))
}