import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.state.isMobile)
      ? (_openBlock(), _createBlock($setup["MoleculeTabs"], {
          key: 0,
          modelValue: $setup.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.tab) = $event)),
          textTooltip: true,
          tabs: $setup.tabs
        }, null, 8 /* PROPS */, ["modelValue", "tabs"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeTabWindow"], {
      modelValue: $setup.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.tab) = $event)),
      class: _normalizeClass({ border: !$setup.isMobile })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["MoleculeTabItem"], { value: "groups" }, {
          default: _withCtx(() => [
            _createVNode($setup["GroupsPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "companys" }, {
          default: _withCtx(() => [
            _createVNode($setup["CompanyPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "units" }, {
          default: _withCtx(() => [
            _createVNode($setup["UnitsPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "typeEquipaments" }, {
          default: _withCtx(() => [
            _createVNode($setup["TypeOfEquipmentPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "equipaments" }, {
          default: _withCtx(() => [
            _createVNode($setup["EquipamentPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "provedor" }, {
          default: _withCtx(() => [
            _createVNode($setup["ProviderPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "internetLink" }, {
          default: _withCtx(() => [
            _createVNode($setup["InternetLinkPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "services" }, {
          default: _withCtx(() => [
            _createVNode($setup["ServicesPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "collectors" }, {
          default: _withCtx(() => [
            _createVNode($setup["CollerctorsPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "triggers" }, {
          default: _withCtx(() => [
            _createVNode($setup["TriggersPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "collectorVersion" }, {
          default: _withCtx(() => [
            _createVNode($setup["CollectorsVersionPage"], { activeTab: $setup.tab }, null, 8 /* PROPS */, ["activeTab"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"])
  ], 64 /* STABLE_FRAGMENT */))
}