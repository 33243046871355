import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_main, null, {
          default: _withCtx(() => [
            ($setup.Authentication.getters.getStatusLoggedIn.value)
              ? (_openBlock(), _createBlock($setup["OrganismsNavigation"], { key: 0 }))
              : _createCommentVNode("v-if", true),
            ($setup.Authentication.getters.getStatusLoggedIn.value)
              ? (_openBlock(), _createBlock($setup["OrganismsBar"], {
                  key: 1,
                  title: $setup.title
                }, null, 8 /* PROPS */, ["title"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", {
              class: _normalizeClass([[$setup.Authentication.getters.getStatusLoggedIn.value ? 'area-content' : ''], "h-100"])
            }, [
              _createVNode(_component_router_view)
            ], 2 /* CLASS */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["AtomPreloader"])
  ], 64 /* STABLE_FRAGMENT */))
}