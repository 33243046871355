import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f3ae12c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "area-icon"
}
const _hoisted_2 = { class: "area-number" }
const _hoisted_3 = { class: "area-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[$setup.props.noBorder ? '' : 'border-r'], "area-info"])
  }, [
    ($setup.props.hasIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("p", _hoisted_2, _toDisplayString($setup.props.number), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString($setup.props.description), 1 /* TEXT */)
  ], 2 /* CLASS */))
}