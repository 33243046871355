<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3846 8.71994C19.7976 8.75394 20.1056 9.11494 20.0726 9.52794C20.0666 9.59594 19.5246 16.3069 19.2126 19.1219C19.0186 20.8689 17.8646 21.9319 16.1226 21.9639C14.7896 21.9869 13.5036 21.9999 12.2466 21.9999C10.8916 21.9999 9.5706 21.9849 8.2636 21.9579C6.5916 21.9249 5.4346 20.8409 5.2456 19.1289C4.9306 16.2889 4.3916 9.59494 4.3866 9.52794C4.3526 9.11494 4.6606 8.75294 5.0736 8.71994C5.4806 8.70894 5.8486 8.99494 5.8816 9.40694C5.88479 9.45035 6.10514 12.184 6.34526 14.8887L6.39349 15.4284C6.51443 16.7728 6.63703 18.0646 6.7366 18.9639C6.8436 19.9369 7.3686 20.4389 8.2946 20.4579C10.7946 20.5109 13.3456 20.5139 16.0956 20.4639C17.0796 20.4449 17.6116 19.9529 17.7216 18.9569C18.0316 16.1629 18.5716 9.47494 18.5776 9.40694C18.6106 8.99494 18.9756 8.70694 19.3846 8.71994ZM14.3454 2.00024C15.2634 2.00024 16.0704 2.61924 16.3074 3.50624L16.5614 4.76724C16.6435 5.18062 17.0062 5.4825 17.4263 5.48913L20.708 5.48924C21.122 5.48924 21.458 5.82524 21.458 6.23924C21.458 6.65324 21.122 6.98924 20.708 6.98924L17.4556 6.98909C17.4505 6.98919 17.4455 6.98924 17.4404 6.98924L17.416 6.98824L7.04162 6.98912C7.03355 6.9892 7.02548 6.98924 7.0174 6.98924L7.002 6.98824L3.75 6.98924C3.336 6.98924 3 6.65324 3 6.23924C3 5.82524 3.336 5.48924 3.75 5.48924L7.031 5.48824L7.13202 5.48185C7.50831 5.43303 7.82104 5.14724 7.8974 4.76724L8.1404 3.55124C8.3874 2.61924 9.1944 2.00024 10.1124 2.00024H14.3454ZM14.3454 3.50024H10.1124C9.8724 3.50024 9.6614 3.66124 9.6004 3.89224L9.3674 5.06224C9.33779 5.21044 9.29467 5.35326 9.23948 5.48951H15.2186C15.1634 5.35326 15.1201 5.21044 15.0904 5.06224L14.8474 3.84624C14.7964 3.66124 14.5854 3.50024 14.3454 3.50024Z"
        fill="black"
      />
    </svg>
  </template>
  
  