<template>
<svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M41.5 20.38H35.72C30.98 20.38 27.12 16.52 27.12 11.78V6C27.12 4.9 26.22 4 25.12 4H16.64C10.48 4 5.5 8 5.5 15.14V32.86C5.5 40 10.48 44 16.64 44H32.36C38.52 44 43.5 40 43.5 32.86V22.38C43.5 21.28 42.6 20.38 41.5 20.38Z" fill="#8897A8"/>
<path d="M32.0994 4.42096C31.2794 3.60096 29.8594 4.16096 29.8594 5.30096V12.281C29.8594 15.201 32.3394 17.621 35.3594 17.621C37.2594 17.641 39.8994 17.641 42.1594 17.641C43.2994 17.641 43.8994 16.301 43.0994 15.501C40.2194 12.601 35.0594 7.38096 32.0994 4.42096Z" fill="#8897A8"/>
<path d="M23.5628 24.94L19.5628 20.94C19.5428 20.92 19.5228 20.92 19.5228 20.9C19.4028 20.78 19.2428 20.68 19.0828 20.6C19.0628 20.6 19.0628 20.6 19.0428 20.6C18.8828 20.54 18.7228 20.52 18.5628 20.5C18.5028 20.5 18.4628 20.5 18.4028 20.5C18.2828 20.5 18.1428 20.54 18.0228 20.58C17.9628 20.6 17.9228 20.62 17.8828 20.64C17.7228 20.72 17.5628 20.8 17.4428 20.94L13.4428 24.94C12.8628 25.52 12.8628 26.48 13.4428 27.06C14.0228 27.64 14.9828 27.64 15.5628 27.06L17.0028 25.62V34C17.0028 34.82 17.6828 35.5 18.5028 35.5C19.3228 35.5 20.0028 34.82 20.0028 34V25.62L21.4428 27.06C21.7428 27.36 22.1228 27.5 22.5028 27.5C22.8828 27.5 23.2628 27.36 23.5628 27.06C24.1428 26.48 24.1428 25.52 23.5628 24.94Z" fill="#8897A8"/>
</svg>
</template>
<script lang="ts" setup>

</script>
