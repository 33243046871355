import { AxiosResponse } from 'axios'
import { httpRelatory, httpAnalitcs } from '../api'
import { Relatory } from '../models'

const controller = 'relatorio'
const controller2 = 'relatorios'

export async function RelatoryAll(dataIni: string, dataFim: string): Promise<any> {

    const { data } = await httpRelatory.get<Relatory[], AxiosResponse>(`${controller}/grafico-resultado-medias-periodo?dataIni=${dataIni}&dataFim=${dataFim}`);
    return data;
}
export async function RelatoryFullTime(qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/tempo-total-inatividade-por-servico?qtdhoras=${qtdhoras}`);
    return data;
}
export async function RelatoryCosume(servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/pico-consumo-banda-por-servico?servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function RelatoryPercent(qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/percentual-tempo-estados-por-servico?qtdhoras=${qtdhoras}`);
    return data;
}
export async function RelatoryLatencyUnity(unidadeid: number, servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/latencia-media-por-unidade?unidadeid=${unidadeid}&servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function RelatoryLatencyState(qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/latencia-media-estados-por-servico?qtdhoras=${qtdhoras}`);
    return data;
}
export async function RelatoryAvailabilityGeral(unidadeid: number, servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/disponibilidade-geral-por-unidade?unidadeid=${unidadeid}&servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function Relatoryconsumption(servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/consumo-medio-por-servico?servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}



export async function PerformanceVariation(servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/variacao-desempenho-por-servico?servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function PerformanceService(servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/desempenho-por-servico?servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function ComparationperformanceService(servicoid: number, qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/comparacao-desempenho-por-servico?servicoid=${servicoid}&qtdhoras=${qtdhoras}`);
    return data;
}
export async function HistoryNotification( qtdhoras: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/historico-notificacao-periodo?qtdhoras=${qtdhoras}`);
    return data;
}
export async function CostTotal(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/custo-total-mensal-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}
export async function DescontTotal(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/desconto-total-mensal-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}
export async function PercentVariation(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/percentual-variacao-mensal-custos-descontos-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}
export async function PercentEconomic(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/porcentagem-economia-mensal-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}
export async function VariationMounthDescont(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/variacao-mensal-descontos-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}
export async function VariationMounthCost(unidadeid: number, mounth: number, year: number): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/variacao-mensal-custos-por-link?unidadeid=${unidadeid}&mes=${mounth}&ano=${year}`);
    return data;
}


export async function DesempenhoInternetPorColetor(): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/desempenho-internet-por-coletor`);
    return data;
}

export async function DesempenhoInternetPorLinkServicos(): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/desempenho-internet-por-linksservicos`);
    return data;
}

export async function DesempenhoInternetPorUnidade(): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/desempenho-internet-por-unidade`);
    return data;
}

export async function MediaVelocidadeInternetPorLocalidade(): Promise<any> {

    const { data } = await httpAnalitcs.get<Relatory[], AxiosResponse>(`${controller2}/media-velocidade-internet-por-localidade`);
    return data;
}