import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b237e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "custom-marker text-center" }
const _hoisted_4 = { class: "text-white mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MoleculeCard"], {
    textTooltip: $props.textTooltip,
    title: $setup.props.title,
    onOnChangeDropDownList: $setup.handleChangeDropdownList,
    "has-dropdown-list": $setup.props.hasDropdownList,
    "options-drop-down": $setup.props.optionsDropDown,
    loading: $setup.props.loading,
    loadingColor: $setup.props.loadingColor,
    class: "mx-md-0 mx-2"
  }, {
    default: _withCtx(() => [
      ($setup.props.hasToggle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["AtomToogleButton"], {
              width: "172px",
              buttons: $setup.props.buttons ?? [],
              onHandleToggle: $setup.handleToogleChange
            }, null, 8 /* PROPS */, ["buttons"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.props.content )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode($setup["AtomSelect"], {
              class: "mx-md-0 mx-5",
              label: "Escolha o País",
              options: $setup.Country,
              modelValue: $setup.countrySelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.countrySelected) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.changeCity($setup.citySelected)))
            }, null, 8 /* PROPS */, ["options", "modelValue"]),
            ($setup.props.optionMaps)
              ? (_openBlock(), _createBlock($setup["AtomSelect"], {
                  key: 0,
                  label: "Escolha o Estado",
                  options: $setup.City,
                  modelValue: $setup.citySelected,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.citySelected) = $event)),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => ($setup.changeCity($setup.citySelected)))
                }, null, 8 /* PROPS */, ["options", "modelValue"]))
              : _createCommentVNode("v-if", true),
            ($setup.props.optionMaps)
              ? (_openBlock(), _createBlock($setup["AtomSelect"], {
                  key: 1,
                  disabled: $setup.citySelected ? false : true,
                  label: "Escolha o município",
                  options: $setup.Municipe,
                  modelValue: $setup.municipeSelected,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.municipeSelected) = $event)),
                  onChange: _cache[5] || (_cache[5] = ($event: any) => ($setup.changeCity($setup.municipeSelected)))
                }, null, 8 /* PROPS */, ["disabled", "options", "modelValue"]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (!$setup.props.optionMaps)
        ? (_openBlock(), _createBlock($setup["VueHighcharts"], {
            key: 2,
            type: "mapChart",
            options: $setup.chartOptions,
            redrawOnUpdate: true,
            oneToOneUpdate: false,
            animateOnUpdate: true
          }, null, 8 /* PROPS */, ["options"]))
        : _createCommentVNode("v-if", true),
      ($setup.props.optionMaps)
        ? (_openBlock(), _createBlock($setup["MapboxMap"], {
            key: 3,
            style: {"height":"400px"},
            zoom: 8,
            center: $setup.mapCenter,
            "access-token": $setup.keyMapBox,
            "map-style": "mapbox://styles/mapbox/streets-v11"
          }, {
            default: _withCtx(() => [
              _createCommentVNode(" dataMaps.map((x) => [x.lng, x.lat]) "),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.data, (item, index) => {
                return (_openBlock(), _createBlock($setup["MapboxMarker"], {
                  key: index,
                  "lng-lat": [item.lat, item.lng]
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("p", _hoisted_4, [
                        _createElementVNode("strong", null, _toDisplayString(item.value), 1 /* TEXT */)
                      ])
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["lng-lat"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["center", "access-token"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["textTooltip", "title", "has-dropdown-list", "options-drop-down", "loading", "loadingColor"]))
}