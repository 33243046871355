import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { GerenteDeContas } from '@/core/models';

const controller = 'gerentesDeContas'

export async function getGerenteDeContasAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<GerenteDeContas[], AxiosResponse>(`${controller}?page=${page}&size=${size}${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getGerenteDeContas(id: string): Promise<GerenteDeContas> {

    const { data } = await httpAnalitcs.get<GerenteDeContas, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postGerenteDeContas(payload: GerenteDeContas): Promise<GerenteDeContas> {

    const { data } = await httpAnalitcs.post<GerenteDeContas, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putGerenteDeContas(payload: GerenteDeContas): Promise<GerenteDeContas> {

    const { data } = await httpAnalitcs.put<GerenteDeContas, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteGerenteDeContas(GerenteDeContasId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${GerenteDeContasId}`);

    return status === 204;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }