<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10.889 1.3335C13.1483 1.3335 14.6663 2.91883 14.6663 5.2775V10.7228C14.6663 13.0815 13.1483 14.6668 10.8877 14.6668H5.10901C2.85034 14.6668 1.33301 13.0815 1.33301 10.7228V5.2775C1.33301 2.91883 2.85034 1.3335 5.10901 1.3335H10.889ZM10.889 2.3335H5.10901C3.42301 2.3335 2.33301 3.48883 2.33301 5.2775V10.7228C2.33301 12.5115 3.42301 13.6668 5.10901 13.6668H10.8877C12.5757 13.6668 13.6663 12.5115 13.6663 10.7228V5.2775C13.6663 3.48883 12.5757 2.3335 10.889 2.3335ZM7.99567 7.50016C8.27167 7.50016 8.49567 7.72416 8.49567 8.00016V10.6668C8.49567 10.9428 8.27167 11.1668 7.99567 11.1668C7.71967 11.1668 7.49567 10.9428 7.49567 10.6668V8.00016C7.49567 7.72416 7.71967 7.50016 7.99567 7.50016ZM7.99894 4.8029C8.36761 4.8029 8.66561 5.1009 8.66561 5.46956C8.66561 5.83823 8.36761 6.13623 7.99894 6.13623C7.63027 6.13623 7.32894 5.83823 7.32894 5.46956C7.32894 5.1009 7.62427 4.8029 7.99227 4.8029H7.99894Z"
      fill="#212529" />
  </svg>
</template>
<script lang="ts" setup>

</script>
