import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_component_v_icon, {
    color: $setup.props.color,
    icon: $setup.props.icon,
    size: $setup.props.size
  }, null, 8 /* PROPS */, ["color", "icon", "size"]))
}