import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { Provedor } from '../models'

const controller = 'provedores'

export async function getProvedorAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<Provedor[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getProvedor(id: string): Promise<Provedor> {

    const { data } = await httpAnalitcs.get<Provedor, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postProvedor(payload: Provedor): Promise<Provedor> {

    const { data } = await httpAnalitcs.post<Provedor, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putProvedor(payload: Provedor): Promise<Provedor> {

    const { data } = await httpAnalitcs.put<Provedor, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteProvedor(ProvedorId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${ProvedorId}`);

    return status === 204;
}

// export async function patchProvedor(payload: Provedor): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }