import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-597a107b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-end"
}
const _hoisted_2 = { class: "d-flex mb-8 mt-6" }
const _hoisted_3 = { class: "text-sm text-muted" }
const _hoisted_4 = { class: "valor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Gerenciar usuários\" /> "),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "border"
        }, {
          default: _withCtx(() => [
            (!$setup.showInfos)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode($setup["AtomButton"], {
                    prependIcon: "",
                    appendIcon: "",
                    background: "white",
                    outlined: "",
                    text: "Mais informações",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.showInfos = true), ["prevent"]))
                  }, {
                    prependIcon: _withCtx(() => [
                      _createVNode($setup["InfoSquareIcon"])
                    ]),
                    appendIcon: _withCtx(() => [
                      _createVNode($setup["ArrowDown3Icon"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : (_openBlock(), _createBlock($setup["MoleculeInfos"], {
                  key: 1,
                  infos: $setup.infos,
                  onHandleToggle: _cache[1] || (_cache[1] = ($event: any) => ($setup.showInfos = false))
                }, null, 8 /* PROPS */, ["infos"])),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.itemsCusto, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "border rounded-lg pa-4 area-custo mr-4",
                  key: item.id
                }, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(item.descricao), 1 /* TEXT */),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(item.valor), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createVNode($setup["MoleculeTable"], {
              onChangePerPage: _cache[2] || (_cache[2] = (ev) => ($setup.perPage = ev)),
              onChangePage: _cache[3] || (_cache[3] = (ev) => ($setup.page = ev)),
              headers: $setup.headers,
              items: $setup.paginationTableData,
              totalItems: $setup.items.length,
              perPage: $setup.perPage,
              page: $setup.page
            }, {
              default: _withCtx(() => [
                _createCommentVNode(" <template #actions=\"{ props }\">\n          <div class=\"d-flex align-center justify-space-between\" style=\"gap: 5px; max-width: 204px\">\n            <AtomButton background=\"transparent\" @click.stop=\"handleRelatorio(props.row)\">\n              <BtnDocumentIcon />\n            </AtomButton>\n          </div>\n        </template> ")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}