<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF5C00"/>
<path d="M10.5814 15.5796C10.3814 15.5796 10.1914 15.4996 10.0514 15.3596L7.22141 12.5296C6.93141 12.2396 6.93141 11.7596 7.22141 11.4696C7.51141 11.1796 7.99141 11.1796 8.28141 11.4696L10.5814 13.7696L15.7214 8.62961C16.0114 8.33961 16.4914 8.33961 16.7814 8.62961C17.0714 8.91961 17.0714 9.39961 16.7814 9.68961L11.1114 15.3596C10.9714 15.4996 10.7814 15.5796 10.5814 15.5796Z" fill="#FF5C00"/>
</svg>


</template>
<script lang="ts" setup>

</script>
