import OrganismsMapCharts from './OrganismsMapCharts.vue';
import OrganismsChart from './OrganismsChart.vue';
import OrganismsNavigation from './OrganismsNavigation.vue';
import OrganismsBar from './OrganismsBar.vue';
import OrganismsPolarChart from './OrganismsPolarChart.vue';
import OrganismsChartSaltos from './OrganismsChartSaltos.vue';
import OrganismsChartQuadrant from './OrganismsChartQuadrant.vue';
import OrganismsTooltipChart from './OrganismsTooltipChart.vue';

export {
  OrganismsMapCharts,
  OrganismsChart,
  OrganismsNavigation,
  OrganismsBar,
  OrganismsPolarChart,
  OrganismsChartSaltos,
  OrganismsChartQuadrant,
  OrganismsTooltipChart
};
