import { AxiosResponse } from 'axios'
import { httpAnalitcs } from '../api'
import { Servicos } from '../models'

const controller = 'servicos'

export async function getServicosAll(page: number, size: number, term?: string, sort: any = { archived: "asc", id: "desc" }): Promise<any> {
    const stringSort = Object.keys(sort).map(b => `${b}:${sort[b]}`).join(',')

    const { data } = await httpAnalitcs.get<Servicos[], AxiosResponse>(`${controller}?page=${page}&size=${size}&archived=0${(term ? `&nome=${term}` : "")}&sort=${stringSort}`);
    return data;
}

export async function getServico(id: string): Promise<Servicos> {

    const { data } = await httpAnalitcs.get<Servicos, AxiosResponse>(`${controller}/${id}`)
    return data;
}

export async function postServico(payload: Servicos): Promise<Servicos> {

    const { data } = await httpAnalitcs.post<Servicos, AxiosResponse>(`${controller}`, payload)
    return data;
}

export async function putServico(payload: Servicos): Promise<Servicos> {

    const { data } = await httpAnalitcs.put<Servicos, AxiosResponse>(`${controller}/${payload.id}`, payload);
    return data;
}

export async function deleteServico(ServicoId: string): Promise<boolean> {

    const { status } = await httpAnalitcs.delete(`${controller}/${ServicoId}`);

    return status === 204;
}

// export async function patchServico(payload: Servicos): Promise<AxiosResponse> {
//     return await httpAnalitcs.patch(`${controller}/${payload.id}`, payload)
// }

export async function getServicoPorUnidade(id: number): Promise<any[]> {

    const { data } = await httpAnalitcs.get<any[], AxiosResponse>(`/servico-por-unidade/${id}`)
    return data;
}
