<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.625 6.66675V8.41675C15.2583 8.36675 14.8417 8.34175 14.375 8.33342V6.66675C14.375 4.04175 13.6333 2.29175 10 2.29175C6.36667 2.29175 5.625 4.04175 5.625 6.66675V8.33342C5.15833 8.34175 4.74167 8.36675 4.375 8.41675V6.66675C4.375 4.25008 4.95833 1.04175 10 1.04175C15.0417 1.04175 15.625 4.25008 15.625 6.66675Z" fill="#697B8E"/>
<path opacity="0.4" d="M18.3327 12.4999V14.1666C18.3327 17.4999 17.4993 18.3333 14.166 18.3333H5.83268C2.49935 18.3333 1.66602 17.4999 1.66602 14.1666V12.4999C1.66602 9.71659 2.24935 8.67492 4.37435 8.41659C4.74102 8.36659 5.15768 8.34159 5.62435 8.33325H14.3743C14.841 8.34159 15.2577 8.36659 15.6243 8.41659C17.7493 8.67492 18.3327 9.71659 18.3327 12.4999Z" fill="#697B8E"/>
<path d="M6.66732 14.1667C6.55898 14.1667 6.45065 14.1417 6.35065 14.1C6.24231 14.0584 6.15899 14.0001 6.07566 13.9251C5.92566 13.7667 5.83398 13.5501 5.83398 13.3334C5.83398 13.2251 5.85897 13.1167 5.90064 13.0167C5.94231 12.9167 6.00066 12.825 6.07566 12.7417C6.15899 12.6667 6.24231 12.6084 6.35065 12.5667C6.65898 12.4334 7.02564 12.5084 7.25898 12.7417C7.33398 12.825 7.39233 12.9167 7.434 13.0167C7.47566 13.1167 7.50065 13.2251 7.50065 13.3334C7.50065 13.5501 7.40898 13.7667 7.25898 13.9251C7.10064 14.0751 6.88398 14.1667 6.66732 14.1667Z" fill="#697B8E"/>
<path d="M9.99935 14.1668C9.77435 14.1668 9.56602 14.0751 9.40769 13.9251C9.33269 13.8418 9.27434 13.7501 9.23267 13.6501C9.191 13.5501 9.16602 13.4418 9.16602 13.3334C9.16602 13.1084 9.25769 12.9001 9.40769 12.7417C9.71602 12.4334 10.2743 12.4334 10.591 12.7417C10.741 12.9001 10.8327 13.1084 10.8327 13.3334C10.8327 13.4418 10.8077 13.5501 10.766 13.6501C10.7244 13.7501 10.666 13.8418 10.591 13.9251C10.4327 14.0751 10.216 14.1668 9.99935 14.1668Z" fill="#697B8E"/>
<path d="M13.3333 14.1668C13.1167 14.1668 12.9 14.0751 12.7417 13.9251C12.5917 13.7668 12.5 13.5584 12.5 13.3334C12.5 13.1084 12.5917 12.9001 12.7417 12.7417C13.0583 12.4334 13.6167 12.4334 13.925 12.7417C13.9583 12.7834 13.9917 12.8251 14.025 12.8751C14.0583 12.9168 14.0833 12.9667 14.1 13.0167C14.125 13.0667 14.1417 13.1168 14.15 13.1668C14.1583 13.2251 14.1667 13.2834 14.1667 13.3334C14.1667 13.5501 14.075 13.7668 13.925 13.9251C13.7667 14.0751 13.55 14.1668 13.3333 14.1668Z" fill="#697B8E"/>
</svg>

</template>
<script lang="ts" setup>

</script>
