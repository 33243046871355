import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-693422a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-auto mb-5" }
const _hoisted_2 = { class: "text-muted" }
const _hoisted_3 = { class: "mx-auto mb-5" }
const _hoisted_4 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_bottom_sheet = _resolveComponent("v-bottom-sheet")!

  return ($setup.state.isMobile)
    ? (_openBlock(), _createBlock(_component_v_bottom_sheet, {
        key: 0,
        modelValue: $setup.localSheet,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.localSheet) = $event))
      }, {
        default: _withCtx(() => [
          ($setup.props.collapseClick)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "radius",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.localSheet = !$setup.localSheet))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createCommentVNode(" Adicione um manipulador de eventos click "),
                    _createVNode($setup["BarMobileIcon"], { class: "cursor" })
                  ]),
                  _createElementVNode("h3", null, _toDisplayString($setup.props.title), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_2, _toDisplayString($setup.props.subtitle), 1 /* TEXT */),
                  _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }))
            : (_openBlock(), _createBlock(_component_v_card, {
                key: 1,
                class: "radius"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createCommentVNode(" Adicione um manipulador de eventos click "),
                    _createVNode($setup["BarMobileIcon"], {
                      class: "cursor",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.localSheet = !$setup.localSheet))
                    })
                  ]),
                  _createElementVNode("h3", null, _toDisplayString($setup.props.title), 1 /* TEXT */),
                  _createElementVNode("span", _hoisted_4, _toDisplayString($setup.props.subtitle), 1 /* TEXT */),
                  _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }))
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["modelValue"]))
    : _createCommentVNode("v-if", true)
}