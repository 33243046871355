<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="4" fill="#FFE7CA"/>
<path d="M12 5C15.857 5 19 8.136 19 12C19 15.857 15.857 19 12 19C8.136 19 5 15.857 5 12C5 8.136 8.136 5 12 5ZM9.942 10.621C9.739 10.411 9.403 10.411 9.2 10.614C8.99 10.824 8.99 11.153 9.2 11.356L11.629 13.799C11.727 13.897 11.86 13.953 12 13.953C12.14 13.953 12.273 13.897 12.371 13.799L14.8 11.356C14.905 11.258 14.954 11.125 14.954 10.992C14.954 10.852 14.905 10.719 14.8 10.614C14.59 10.411 14.261 10.411 14.058 10.621L12 12.686L9.942 10.621Z" fill="#FF7C02"/>
</svg>


</template>
<script lang="ts" setup>

</script>
