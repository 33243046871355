<template>
<svg width="155" height="79" viewBox="0 0 155 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M137.64 55.7493C109.181 35.8266 98.0696 31.642 67.9517 29.5058C23.2346 26.3279 -16.4263 -8.80526 7.04482 -57.9657C25.0989 -95.7738 66.6864 -125.604 101.22 -125.516C159.372 -125.369 224.144 -76.948 241.731 -21.4794C256.896 26.3379 234.455 72.3928 193.002 78.5285C178.942 80.6098 164.897 74.832 137.64 55.7493Z" fill="#FF5C00"/>
</svg>


</template>
<script lang="ts" setup>

</script>
