<template>
 
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19.5" stroke="#B2BBC7" />
      <path
        d="M29.0702 13.23C27.4602 13.07 25.8502 12.95 24.2302 12.86V12.85L24.0102 11.55C23.8602 10.63 23.6402 9.25 21.3002 9.25H18.6802C16.3502 9.25 16.1302 10.57 15.9702 11.54L15.7602 12.82C14.8302 12.88 13.9002 12.94 12.9702 13.03L10.9302 13.23C10.5102 13.27 10.2102 13.64 10.2502 14.05C10.2902 14.46 10.6502 14.76 11.0702 14.72L13.1102 14.52C18.3502 14 23.6302 14.2 28.9302 14.73C28.9602 14.73 28.9802 14.73 29.0102 14.73C29.3902 14.73 29.7202 14.44 29.7602 14.05C29.7902 13.64 29.4902 13.27 29.0702 13.23Z"
        fill="#546375" />
      <path opacity="0.3991"
        d="M27.2302 16.14C26.9902 15.89 26.6602 15.75 26.3202 15.75H13.6802C13.3402 15.75 13.0002 15.89 12.7702 16.14C12.5402 16.39 12.4102 16.73 12.4302 17.08L13.0502 27.34C13.1602 28.86 13.3002 30.76 16.7902 30.76H23.2102C26.7002 30.76 26.8402 28.87 26.9502 27.34L27.5702 17.09C27.5902 16.73 27.4602 16.39 27.2302 16.14Z"
        fill="#546375" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M17.5801 25C17.5801 24.5858 17.9159 24.25 18.3301 24.25H21.6601C22.0743 24.25 22.4101 24.5858 22.4101 25C22.4101 25.4142 22.0743 25.75 21.6601 25.75H18.3301C17.9159 25.75 17.5801 25.4142 17.5801 25Z"
        fill="#546375" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16.75 21C16.75 20.5858 17.0858 20.25 17.5 20.25H22.5C22.9142 20.25 23.25 20.5858 23.25 21C23.25 21.4142 22.9142 21.75 22.5 21.75H17.5C17.0858 21.75 16.75 21.4142 16.75 21Z"
        fill="#546375" />
    </svg>

  
</template>
