<template>


<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M13.24 20.02C8.26391 20.02 4.23 15.9861 4.23 11.01C4.23 6.03391 8.26391 2 13.24 2C18.2161 2 22.25 6.03391 22.25 11.01C22.25 15.9861 18.2161 20.02 13.24 20.02Z" fill="white"/>
<path d="M2.25995 18.95C2.58995 18.34 3.28995 18 4.22995 18C4.93995 18 5.54995 18.29 5.90995 18.79C6.26995 19.29 6.34995 19.96 6.12995 20.63C5.69995 21.93 4.94995 22.22 4.53995 22.27C4.47995 22.28 4.41995 22.28 4.34995 22.28C3.90995 22.28 3.22995 22.09 2.56995 21.1C2.03995 20.33 1.93995 19.56 2.25995 18.95Z" fill="white"/>
</svg>

</template>
<script lang="ts" setup>

</script>
