import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-011f406c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "label-mobile"
}
const _hoisted_3 = ["value", "maxLength", "required", "type", "min", "max", "placeholder"]
const _hoisted_4 = ["value", "maxLength", "required", "type", "min", "max", "disabled", "placeholder"]
const _hoisted_5 = ["value", "required", "type", "min", "max", "maxLength", "placeholder"]
const _hoisted_6 = ["value", "required", "type", "min", "max", "maxLength", "disabled", "placeholder"]
const _hoisted_7 = {
  key: 4,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.props.label && !$setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile && $setup.props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (!$setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["input-wapper d-flex", { error: $props.hasError }])
        }, [
          ($props.prependIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([$setup.props.classes ?? '', "prependIcon h-100 px-2 d-flex align-center"])
              }, [
                _renderSlot(_ctx.$slots, "prependIcon", {}, undefined, true)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (!$props.disabled)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 1,
                value: $setup.props.modelValue,
                ref: "input",
                maxLength: $setup.props.maxLength,
                required: $props.required
              }, $setup.attributes, {
                type: $props.type,
                min: $props.min,
                max: $props.max,
                class: $setup.props.classes ? $setup.props.classes : 'radius-input'
              }, _toHandlers($setup.listeners, true), { placeholder: $props.placeholder }), null, 16 /* FULL_PROPS */, _hoisted_3)), [
                [_directive_maska, $props.mask]
              ])
            : _createCommentVNode("v-if", true),
          ($props.disabled)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 2,
                class: "disabled",
                value: $setup.props.modelValue,
                ref: "input",
                maxLength: $setup.props.maxLength,
                required: $props.required
              }, $setup.attributes, {
                type: $props.type,
                min: $props.min,
                max: $props.max,
                class: $setup.props.classes ? $setup.props.classes : 'radius-input'
              }, _toHandlers($setup.listeners, true), {
                disabled: $props.disabled,
                placeholder: $props.placeholder
              }), null, 16 /* FULL_PROPS */, _hoisted_4)), [
                [_directive_maska, $props.mask]
              ])
            : _createCommentVNode("v-if", true),
          ($props.appendIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass([{ 'bg-gray-06': !$props.noBgAppendIcon && !$setup.props.bgIcon, 'border-s': !$props.noBorderPrependIcon, error: $props.hasError }, "appendIcon h-100 px-2 d-flex align-center"]),
                style: _normalizeStyle(`background: ${!!$setup.props.bgIcon ? $setup.props.bgIcon : ''}`)
              }, [
                _renderSlot(_ctx.$slots, "appendIcon", {}, undefined, true)
              ], 6 /* CLASS, STYLE */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["input-wapper-mobile d-flex", { error: $props.hasError }])
        }, [
          ($props.prependIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([$setup.props.classes ?? '', "prependIcon h-100 px-2 d-flex align-center"])
              }, [
                _renderSlot(_ctx.$slots, "prependIcon", {}, undefined, true)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          (!$props.disabled)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 1,
                value: $setup.props.modelValue,
                ref: "input",
                required: $props.required
              }, $setup.attributes, {
                type: $props.type,
                min: $props.min,
                max: $props.max,
                maxLength: $setup.props.maxLength,
                class: $setup.props.classes ? $setup.props.classes : 'radius-input-mobile '
              }, _toHandlers($setup.listeners, true), { placeholder: $props.placeholder }), null, 16 /* FULL_PROPS */, _hoisted_5)), [
                [_directive_maska, $props.mask]
              ])
            : _createCommentVNode("v-if", true),
          ($props.disabled)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 2,
                class: "disabled",
                value: $setup.props.modelValue,
                ref: "input",
                required: $props.required
              }, $setup.attributes, {
                type: $props.type,
                min: $props.min,
                max: $props.max,
                maxLength: $setup.props.maxLength,
                class: $setup.props.classes ? $setup.props.classes : 'radius-input-mobile '
              }, _toHandlers($setup.listeners, true), {
                disabled: $props.disabled,
                placeholder: $props.placeholder
              }), null, 16 /* FULL_PROPS */, _hoisted_6)), [
                [_directive_maska, $props.mask]
              ])
            : _createCommentVNode("v-if", true),
          ($props.appendIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass([{
          'bg-gray-06': !$props.noBgAppendIcon && !$setup.props.bgIcon,
          'border-s': !$props.noBorderPrependIcon,
          error: $props.hasError
        }, "appendIcon h-100 px-2 d-flex align-center"]),
                style: _normalizeStyle(`background: ${!!$setup.props.bgIcon ? $setup.props.bgIcon : ''}`)
              }, [
                _renderSlot(_ctx.$slots, "appendIcon", {}, undefined, true)
              ], 6 /* CLASS, STYLE */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($props.hasError && $props.msgError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_7, [
          _createVNode($setup["DangerIcon"], { class: "mt-2" }),
          _createTextVNode(" " + _toDisplayString($props.msgError), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}