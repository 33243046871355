<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M10.5003 18.3334C15.1027 18.3334 18.8337 14.6025 18.8337 10.0001C18.8337 5.39771 15.1027 1.66675 10.5003 1.66675C5.89795 1.66675 2.16699 5.39771 2.16699 10.0001C2.16699 14.6025 5.89795 18.3334 10.5003 18.3334Z"
      fill="white"
    />
    <path
      d="M9.45006 13.5666C9.29173 13.5666 9.1334 13.5082 9.0084 13.3832C8.76673 13.1416 8.76673 12.7416 9.0084 12.4999L11.5084 9.99989L9.0084 7.49989C8.76673 7.25822 8.76673 6.85822 9.0084 6.61655C9.25007 6.37489 9.65007 6.37489 9.89173 6.61655L12.8334 9.55822C13.0751 9.79989 13.0751 10.1999 12.8334 10.4416L9.89173 13.3832C9.76673 13.5082 9.6084 13.5666 9.45006 13.5666Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts" setup></script>
