<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M13.9987 25.6666C20.442 25.6666 25.6654 20.4432 25.6654 13.9999C25.6654 7.5566 20.442 2.33325 13.9987 2.33325C7.55538 2.33325 2.33203 7.5566 2.33203 13.9999C2.33203 20.4432 7.55538 25.6666 13.9987 25.6666Z" fill="#FF5C00"/>
<path d="M18.6654 13.1249H14.8737V9.33325C14.8737 8.85492 14.477 8.45825 13.9987 8.45825C13.5204 8.45825 13.1237 8.85492 13.1237 9.33325V13.1249H9.33203C8.8537 13.1249 8.45703 13.5216 8.45703 13.9999C8.45703 14.4783 8.8537 14.8749 9.33203 14.8749H13.1237V18.6666C13.1237 19.1449 13.5204 19.5416 13.9987 19.5416C14.477 19.5416 14.8737 19.1449 14.8737 18.6666V14.8749H18.6654C19.1437 14.8749 19.5404 14.4783 19.5404 13.9999C19.5404 13.5216 19.1437 13.1249 18.6654 13.1249Z" fill="#FF5C00"/>
</svg>

</template>
<script lang="ts" setup>

</script>
