<template>
<svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_843_100777)">
<mask id="path-1-inside-1_843_100777" fill="white">
<path d="M8 40C8 22.3269 22.3269 8 40 8C57.6731 8 72 22.3269 72 40C72 57.6731 57.6731 72 40 72C22.3269 72 8 57.6731 8 40Z"/>
</mask>
<path d="M8 40C8 22.3269 22.3269 8 40 8C57.6731 8 72 22.3269 72 40C72 57.6731 57.6731 72 40 72C22.3269 72 8 57.6731 8 40Z" fill="#292E36"/>
<path d="M8 8H72H8ZM72 42C72 60.7777 56.7777 76 38 76C19.2223 76 4 60.7777 4 42L12 40C12 55.464 24.536 68 40 68C57.6731 68 72 55.464 72 40V42ZM38 76C19.2223 76 4 60.7777 4 42C4 23.2223 19.2223 8 38 8H40C24.536 8 12 22.3269 12 40C12 55.464 24.536 68 40 68L38 76ZM72 8V72V8Z" fill="url(#paint0_linear_843_100777)" mask="url(#path-1-inside-1_843_100777)"/>
<path d="M31.0407 27.5167H48.9373C50.4307 27.5167 51.644 28.73 51.644 30.2233V33.21C51.644 34.3067 50.9673 35.66 50.2907 36.3367L44.4573 41.4933C43.6407 42.17 43.104 43.5233 43.104 44.62V50.4533C43.104 51.27 42.5673 52.3433 41.8907 52.7633L40.0007 53.9533C38.2273 55.05 35.8007 53.8133 35.8007 51.6433V44.4567C35.8007 43.5 35.264 42.2867 34.704 41.61L29.5473 36.1733C28.8707 35.52 28.334 34.2833 28.334 33.4667V30.3633C28.334 28.73 29.5473 27.5167 31.0407 27.5167Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_843_100777" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="4" dy="4"/>
<feGaussianBlur stdDeviation="6"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_843_100777"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_843_100777" result="shape"/>
</filter>
<linearGradient id="paint0_linear_843_100777" x1="-54.5" y1="16.5" x2="87.5" y2="46" gradientUnits="userSpaceOnUse">
<stop stop-color="#9BA7B5"/>
<stop offset="1" stop-color="#45505F"/>
</linearGradient>
</defs>
</svg>
</template>
<script lang="ts" setup>

</script>
