<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.8399 11.6666C6.61841 11.6666 6.43536 11.502 6.40639 11.2885L6.4024 11.2291L6.40275 3.53846L3.63569 6.31687C3.4652 6.48809 3.18819 6.48869 3.01697 6.3182C2.86132 6.16321 2.84667 5.92019 2.97339 5.74864L3.01564 5.69948L6.52964 2.17032C6.55211 2.14775 6.57643 2.12814 6.60212 2.11151C6.60985 2.10681 6.61776 2.10202 6.62583 2.09749C6.63274 2.09332 6.63992 2.08955 6.64718 2.08599C6.65775 2.08105 6.66866 2.07623 6.6798 2.07185C6.68839 2.06829 6.69674 2.06531 6.70515 2.0626C6.71559 2.05938 6.72671 2.05625 6.73801 2.05355C6.74589 2.05155 6.75348 2.04996 6.7611 2.04858C6.77243 2.04664 6.78416 2.04498 6.79603 2.0438C6.80508 2.04278 6.81405 2.04216 6.82303 2.04182C6.82847 2.04174 6.83417 2.04163 6.8399 2.04163L6.85685 2.04185C6.86543 2.04218 6.87401 2.04277 6.88257 2.04361L6.8399 2.04163C6.86751 2.04163 6.89452 2.04418 6.92071 2.04907C6.92696 2.05021 6.93338 2.05156 6.93978 2.05306C6.95295 2.05616 6.96563 2.05976 6.97807 2.06389C6.98424 2.06592 6.99084 2.06828 6.99739 2.07081C7.01056 2.07592 7.02311 2.08148 7.03533 2.08759C7.04108 2.09044 7.0471 2.09362 7.05305 2.09695C7.06277 2.10241 7.072 2.10805 7.08101 2.114C7.08738 2.1182 7.09401 2.12286 7.10053 2.12772L7.10557 2.1315C7.12097 2.14329 7.13557 2.15608 7.14926 2.16977L7.14966 2.17029L10.6642 5.69946C10.8347 5.87067 10.8342 6.14768 10.663 6.31818C10.5073 6.47318 10.2642 6.48679 10.0932 6.35936L10.0442 6.31689L7.27775 3.53963L7.2774 11.2291C7.2774 11.4708 7.08152 11.6666 6.8399 11.6666Z"
      :fill="color || '#15D095'"
    />
  </svg>
</template>
<script lang="ts" setup>

</script>
