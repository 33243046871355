<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M29.3337 16.0001C29.3337 23.3639 23.3641 29.3334 16.0003 29.3334C8.63653 29.3334 2.66699 23.3639 2.66699 16.0001C2.66699 8.63628 8.63653 2.66675 16.0003 2.66675C23.3641 2.66675 29.3337 8.63628 29.3337 16.0001Z" fill="#FF5C00"/>
<path d="M21.7069 17.6799C21.7069 17.4266 21.6135 17.1732 21.4135 16.9732L16.7069 12.2666C16.3202 11.8799 15.6802 11.8799 15.2935 12.2666L10.5869 16.9732C10.2002 17.3599 10.2002 17.9999 10.5869 18.3866C10.9735 18.7732 11.6135 18.7732 12.0002 18.3866L16.0002 14.3866L20.0002 18.3866C20.3869 18.7732 21.0269 18.7732 21.4135 18.3866C21.6135 18.1999 21.7069 17.9466 21.7069 17.6799Z" fill="#FF5C00"/>
</svg>

  </template>
  <script lang="ts" setup>
  
  </script>
  